import { lazy } from "react";
import { Route } from "react-router-dom";

const AllUsersPage = lazy(() => import("./UserList/AllUsersPage"));
const EditUserPage = lazy(() => import("./EditUserPage/EditUserPage"));
const InviteStart = lazy(() => import("./Invites/InviteStart"));
const InviteClientPage = lazy(() => import("./Invites/InviteClientPage"));
const InviteClientUsersPage = lazy(() => import("./Invites/InviteClientUsersPage"));
const InviteEmployeePage = lazy(() => import("./Invites/InviteEmployeePage"));

export const userRouter = (
  <>
    <Route path="/invite" element={<InviteStart />} />

    <Route path="/invite-employee" element={<InviteEmployeePage />} />

    <Route path="/invite-client" element={<InviteClientPage />} />
    <Route path="/invite-client-users/:clientCompanyId" element={<InviteClientUsersPage />} />

    <Route path="/user-corporate-info/:userId" element={<EditUserPage />} />
    <Route path="/users" element={<AllUsersPage />} />
  </>
);
export default userRouter;
