import { useAuth } from "src/core/Auth/useAuth";
import { useBrowserTabTitle } from "src/core/BrowserTabTitle/useBrowserTabTitle";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { PageContainer } from "src/uiKit/Layouts/PageContainer";

import { Stack, Typography } from "@mui/material";

import CelebrationsCard from "../TimeOff/CelebrationsCard";
import { TimeOffCard } from "../TimeOff/TimeOffCard";
import { WhoIsOutCard } from "../TimeOff/WhoIsOutCard";

export function DashboardPropeople(): JSX.Element {
  useBrowserTabTitle("Dashboard");
  const auth = useAuth();
  const { users } = useUsers();
  const uid = auth.uid || "";
  const userName = users[uid]?.fullName || "";
  const userEmail = users[uid]?.email || "";
  const avatarURL = users[uid]?.photoURL || "";
  const jobRole = users[uid]?.role || "";

  return (
    <PageContainer>
      <Stack
        sx={{
          gap: "40px",
          paddingTop: "20px",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <ColorTextAvatar
            sx={{
              fontSize: "1rem",
              width: "80px",
              height: "80px",
            }}
            name={userName || userEmail || ""}
            src={avatarURL || ""}
          />
          <Stack>
            <Typography variant="h3">{userName || userEmail || ""}</Typography>
            <Typography variant="body2" color="text.secondary">
              {jobRole}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "grid",
            width: "100%",
            gap: "20px",
            gridTemplateColumns: "1fr 1fr 1fr",
            minHeight: "320px",
            "@media (max-width: 900px)": {
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
              minHeight: "auto",
            },
            "@media (max-width: 700px)": {
              gridTemplateColumns: "1fr",
              gap: "10px",
              minHeight: "auto",
            },
          }}
        >
          <TimeOffCard />
          <CelebrationsCard />
          <WhoIsOutCard />
        </Stack>
      </Stack>
    </PageContainer>
  );
}

export default DashboardPropeople;
