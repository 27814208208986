import { FirebaseError } from "@firebase/util";
import { t } from "@lingui/core/macro";
import { User } from "firebase/auth";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { useState } from "react";
import { PasswordTextField } from "src/uiKit/Input/PasswordTextField";
import { useNotification } from "src/uiKit/Notification/useNotification";

import DoneIcon from "@mui/icons-material/Done";
import { Button, Stack, Typography } from "@mui/material";

import { ValidatePasswordResult, validatePassword } from "../validators";

interface ChangePasswordFormProps {
  user: User;
  onClose: () => void;
}

export function ChangePasswordForm({ user, onClose }: ChangePasswordFormProps): JSX.Element {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const notification = useNotification();

  const [error, setError] = useState<ValidatePasswordResult | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const postNewPassword = async (oldPassword: string, newPassword: string) => {
    const credential = EmailAuthProvider.credential(user.email || "", oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const passwordValidationResult = validatePassword({
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      confirmNewPassword: formData.confirmPassword,
    });

    if (
      passwordValidationResult.confirmPasswordError ||
      passwordValidationResult.newPasswordError ||
      passwordValidationResult.oldPasswordError
    ) {
      setError(passwordValidationResult);
      return;
    }

    try {
      await postNewPassword(formData.oldPassword, formData.newPassword);
      notification.showNotification({
        message: t`Your password has been changed successfully`,
        type: "success",
      });
      onClose();
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === "auth/wrong-password") {
          setError({
            oldPasswordError: t`Password is incorrect`,
          });
          return;
        }
      }

      return setError({
        oldPasswordError: t`Error: Something goes wrong. Try later`,
      });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack
        sx={{
          gap: "30px",
        }}
      >
        <Typography variant="h3">{t`Change Password`}</Typography>

        <Stack
          sx={{
            gap: "15px",
          }}
        >
          <PasswordTextField
            label={t`Old Password`}
            name="oldPassword"
            value={formData.oldPassword}
            onChange={handleChange}
            placeholder={t`Your old password`}
            error={!!error && !!error.oldPasswordError}
            helperText={error?.oldPasswordError}
          />

          <PasswordTextField
            label={t`New Password`}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder={t`Your new password`}
            error={!!error && !!error.newPasswordError}
            helperText={error?.newPasswordError}
          />
          <PasswordTextField
            label={t`Confirm Password`}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder={t`Your new password`}
            error={!!error && !!error.confirmPasswordError}
            helperText={error?.confirmPasswordError}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Button startIcon={<DoneIcon />} variant="contained" type="submit">
            {t`Done`}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
