import { Context, createContext } from "react";

import { CVPreview } from "@common/cv.types";

export interface CvPreviewElement {
  id: string;
  data: CVPreview;
}

export interface CvPreviewContext {
  cvData: Record<string, CVPreview | undefined>; // id -> CVPreview
  getCvData: (ids: string[]) => Promise<CvPreviewElement[]>;
  getCandidateName: (cvId: string) => Promise<string>;
}

export const cvPreviewContext: Context<CvPreviewContext> = createContext<CvPreviewContext>({
  cvData: {},
  getCvData: () => Promise.resolve([]),
  getCandidateName: () => Promise.resolve(""),
});
