import { t } from "@lingui/core/macro";
import { useNavigate } from "react-router-dom";
import { useHiringFeed } from "src/prohiring/Dashboard/useHiringFeed/useHiringFeed";
import { Loader } from "src/uiKit/Loader/Loader";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Button, Stack, Typography } from "@mui/material";

interface ActionFeedProps {
  onClose?: () => void;
}
export function ActionFeed({ onClose }: ActionFeedProps): JSX.Element {
  const navigate = useNavigate();
  const hiringFeed = useHiringFeed();

  return (
    <Box>
      {hiringFeed.actionInfo.length === 0 && !hiringFeed.loading && (
        <Typography variant="body2" color={COLORS["text-secondary"]}>
          {t`No actions required`}
        </Typography>
      )}

      {hiringFeed.loading && <Loader padding="0" />}

      {hiringFeed.actionInfo
        .sort((a, b) => {
          if (a.isNeedAttention && !b.isNeedAttention) return -1;
          if (!a.isNeedAttention && b.isNeedAttention) return 1;
          return a.timeLeft - b.timeLeft;
        })
        .map((action, index) => {
          const accentColor =
            action.urgency === "hight"
              ? COLORS["error"]
              : action.urgency === "middle"
                ? COLORS["warning"]
                : COLORS["success"];
          const isNeedAttention = action.isNeedAttention;
          return (
            <Stack
              key={index}
              onClick={() => {
                navigate(action.redirectUrlPath);
                onClose?.();
              }}
              sx={{
                borderBottom: `1px solid ${COLORS["bg"]}`,
                flexDirection: "row",
                gap: "10px",
                justifyContent: "space-between",
                padding: "10px",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: COLORS["bg"],
                },
                ".timeLeftStack": {
                  display: "none",
                },

                "@media (max-width: 1500px)": {
                  ".timeLeftStack": {
                    display: "block",
                  },
                  ".timeLeftFixed": {
                    display: "none",
                  },
                },
                "@media (max-width: 900px)": {
                  ".actionFeedCircle": {
                    display: "none",
                  },
                },
                "@media (max-width: 1200px)": {
                  padding: "4px 5px",
                },
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: "12px" }}>
                <Box
                  className="actionFeedCircle"
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: accentColor,
                    marginTop: "10px",
                    flexShrink: 0,
                  }}
                />

                <Stack>
                  <Typography
                    color={COLORS["text-secondary"]}
                    sx={{
                      lineHeight: "1.2",
                    }}
                  >
                    {action.title}
                  </Typography>
                  <Typography variant="caption" color={accentColor} className="timeLeftStack">
                    {action.timeLeftString}
                  </Typography>
                  <Typography variant="caption" color={COLORS["text-secondary"]}>
                    {action.subTitle}
                  </Typography>

                  {isNeedAttention && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: COLORS.error,
                        border: `1px solid ${COLORS.error}`,
                        padding: "2px 12px",
                        borderRadius: "3px",
                        width: "max-content",
                      }}
                    >
                      {t`Need special attention`}
                    </Typography>
                  )}

                  {action.captionInfo && (
                    <Typography variant="caption" color={COLORS["text-disabled"]}>
                      {action.captionInfo}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Typography
                variant="caption"
                color={accentColor}
                className="timeLeftFixed"
                sx={{ paddingTop: "3px" }}
                align="right"
              >
                {action.timeLeftString}
              </Typography>
            </Stack>
          );
        })}

      {!hiringFeed.isFullLoaded && (
        <Stack sx={{ padding: "10px" }}>
          <Button onClick={hiringFeed.showMore}>{t`Show more...`}</Button>
        </Stack>
      )}
    </Box>
  );
}

export default ActionFeed;
