import { t } from "@lingui/core/macro";
import { useEffect, useState } from "react";
import { Loader } from "src/uiKit/Loader/Loader";

import { Button, MenuItem, Stack, TextField } from "@mui/material";

import { AuthTitle } from "../style";
import { useAuth } from "../useAuth";

export const CompanySelectForm = () => {
  const auth = useAuth();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (auth.isSuperAdmin) {
      const searchParam = new URLSearchParams(location.search);
      const domainNameFromUrl = searchParam.get("company") || "";
      setSelectedCompany(domainNameFromUrl);
    }

    if (selectedCompany) return;
    setIsLoading(false);
    if (auth.usersCompanies.length === 0) return;

    const company = auth.getSelectedCompanyFromLocalStorage();
    const isCompanyInList = auth.usersCompanies.includes(company);
    if (isCompanyInList) {
      setSelectedCompany(company);
    } else {
      setSelectedCompany(auth.usersCompanies[0]);
    }
  }, [auth.usersCompanies]);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);
    auth.setSelectedCompanyToLocalStorage(selectedCompany);
    await auth.selectCompany(selectedCompany);
  };

  return (
    <Stack gap={"20px"} component={"form"} onSubmit={submit}>
      <AuthTitle>Sign In</AuthTitle>
      <Stack
        style={{
          gap: "25px",
        }}
      >
        <TextField
          select
          label={t`Select company`}
          value={selectedCompany}
          disabled={!selectedCompany || isLoading || isProcessing}
          onChange={(e) => {
            const id = e.target.value;
            setSelectedCompany(id);
          }}
        >
          {auth.usersCompanies.map((company) => {
            return (
              <MenuItem value={company} key={company}>
                {company}
              </MenuItem>
            );
          })}

          {auth.isSuperAdmin && (
            <MenuItem value={selectedCompany} key={selectedCompany}>
              {selectedCompany}
            </MenuItem>
          )}
        </TextField>

        <Button
          variant={"contained"}
          disabled={!selectedCompany || isLoading || isProcessing}
          onClick={submit}
        >
          {t`Continue`}
        </Button>
        {(isLoading || auth.loading || isProcessing) && <Loader padding="0" />}
      </Stack>
    </Stack>
  );
};
