import { lazy } from "react";
import { Route } from "react-router-dom";

const MyInfoPage = lazy(() => import("./MyInfoPage"));

export const myInfoPageRouter = (
  <>
    <Route path="/my-info" element={<MyInfoPage />} />
  </>
);
