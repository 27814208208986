import { getFullUrlWithDomainTrim, isExtendedUrl } from "../Url/url";
import BaseHeader, { HeaderMenuElement } from "./BaseHeader";

export default function StaticHeader(): JSX.Element {
  const menuItems: HeaderMenuElement[] = [];
  const signInLink = getFullUrlWithDomainTrim("auth") + "/sign-in";
  const signUpLink = getFullUrlWithDomainTrim("auth") + "/sign-up-create-account";

  if (isExtendedUrl) {
    menuItems.push({ linkTo: signInLink, title: "Sign In", type: "Tab" });
  } else {
    menuItems.push({
      linkTo: signUpLink,
      title: "Sign up",
      type: "Tab",
    });

    menuItems.push({
      title: "Sign In",
      linkTo: signInLink,
      type: "EmptyButton",
    });
  }

  return <BaseHeader rightHeaderMenuItems={menuItems} viewBreakpointWidth={870} />;
}
