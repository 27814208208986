import { useEffect, useRef } from "react";

import { Stack, Typography } from "@mui/material";

import { useAuth } from "./useAuth";

export const LogOut = () => {
  const auth = useAuth();
  const isInProcess = useRef(false);

  useEffect(() => {
    if (isInProcess.current) {
      return;
    }

    isInProcess.current = true;

    auth.logout();
  }, [auth]);

  return (
    <Stack>
      <Typography variant="h4">Log out</Typography>
    </Stack>
  );
};
