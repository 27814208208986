import styled from "@emotion/styled";
import { ReactNode } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Typography } from "@mui/material";
import { Link } from "@mui/material";

export const ExternalServiceSignIn = styled(Link)({
  gap: "0.5rem",
  margin: "0.7rem 0",
  color: COLORS["text"],
  display: "flex",
  textDecoration: "none",
  alignItems: "center",
  cursor: "pointer",
});

export const AuthModalContainer = styled.div({
  background: "linear-gradient(145deg, rgba(38,122,211,1) 0%, rgba(57,164,192,1) 100%)",
  backgroundImage: `url(/background.webp)`,
  backgroundSize: "cover",

  boxSizing: "border-box",
  display: "flex",
  padding: "22vh 3vw 3vh 3vw",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  height: "100%",
  "@media (max-height: 600px)": {
    padding: "10vh 3vw 3vh 3vw",
  },
  "@media (max-height: 500px)": {
    justifyContent: "end",
    padding: "3vh 3vw 0 3vw",
  },
});

export const AuthModal = styled.div({
  borderRadius: "7px",
  width: "min(500px, 100vw)",
  boxSizing: "border-box",
  padding: "min(5vh, 2.9rem) min(4vw, 5rem)",
  display: "flex",
  boxShadow: "var(--shadow-xl)",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: COLORS["text-contrast"],
  maxHeight: "100%",
  overflow: "auto",
  "@media (max-height: 500px)": {
    borderRadius: "7px 7px 0 0",
  },
});

export const ErrorInfo = styled.p({
  color: COLORS["error"],
  ":not(:empty):before": {
    content: "'Error: '",
  },
});

export const AuthTitle2 = styled(Typography)({
  textAlign: "center",
});

export const AuthTitle: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      variant="h2"
      component="h2"
      sx={{
        textAlign: "center",
      }}
    >
      {children}
    </Typography>
  );
};

export const Email: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography color={COLORS["text"]} variant="body1" component="span">
      {children}
    </Typography>
  );
};
