import dayjs from "dayjs";

import { Vacancy } from "@common/vacancy.types";

export const getFullVacancyTitle = (vacancy: Vacancy) => {
  const attribute = (vacancy.attribute || "").trim();
  const attributeTitle = attribute ? ` - ${attribute}` : "";
  const seniorityLevel = (vacancy.seniorityLevel || "").trim();
  const jobTitle = (vacancy.jobTitle || "").trim();
  const jobTitleWithSeniority = [jobTitle, seniorityLevel].filter(Boolean).join(", ");

  const dataTitle = `${jobTitleWithSeniority} ${attributeTitle}`.trim();
  if (dataTitle) {
    return dataTitle;
  }

  return `Unknown vacancy | ` + dayjs(vacancy.updatedAt).format("DD.MM.YYYY HH:mm");
};
