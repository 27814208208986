import { t } from "@lingui/core/macro";
import { MRT_Localization } from "material-react-table";
import { MRT_Localization_AR } from "material-react-table/locales/ar";
import { MRT_Localization_DA } from "material-react-table/locales/da";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_ID } from "material-react-table/locales/id";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { MRT_Localization_JA } from "material-react-table/locales/ja";
import { MRT_Localization_KO } from "material-react-table/locales/ko";
import { MRT_Localization_NO } from "material-react-table/locales/no";
import { MRT_Localization_PL } from "material-react-table/locales/pl";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import { MRT_Localization_UK } from "material-react-table/locales/uk";
import { MRT_Localization_VI } from "material-react-table/locales/vi";
import { MRT_Localization_ZH_HANS } from "material-react-table/locales/zh-Hans";
import { useMemo } from "react";
import { SupportedLanguage, defaultLanguage } from "src/core/Language/lang";
import { scrollTop } from "src/core/Utils/scroll";
import { typographyExtraSmall, typographySmaller } from "src/uiKit/Typography/style";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { useUserSettings } from "../Users/hooks/useUserSettings";

export const onRowClick = <T>(callback: (row: T, isNewTab: boolean) => void) => {
  const clickOnTarget = (row: T, target: HTMLElement, isNewTab: boolean) => {
    const actionableTags = ["a", "button", "input", "select", "textarea"];

    const isParentHasClassList = (
      element: HTMLElement | null,
      classListToCheck: string[],
      checkTag = false,
    ): boolean => {
      if (!element) return false;

      const elementClasses = element.classList;
      const isHas = classListToCheck.some((className) => elementClasses.contains(className));
      if (isHas) {
        return true;
      }

      if (checkTag && actionableTags.includes(element.tagName.toLowerCase())) {
        return true;
      }
      return isParentHasClassList(element.parentElement, classListToCheck, checkTag);
    };

    const classNamesForDrop = ["MuiButtonBase-root"];
    const isActionableElement = isParentHasClassList(target, classNamesForDrop, true);
    if (isActionableElement) {
      return;
    }

    const isClickOnTable = isParentHasClassList(target, ["MuiTableCell-root"]);
    if (!isClickOnTable) {
      return;
    }

    callback(row, isNewTab);

    if (!isNewTab) {
      scrollTop();
    }
  };

  return {
    muiTableBodyRowProps: ({ row }: { row: T }) => ({
      onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const targetElement = event.target as HTMLElement;
        const isNewTab = event.ctrlKey || event.metaKey || event.button === 1;
        clickOnTarget(row, targetElement, isNewTab);
      },
      onAuxClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const targetElement = event.target as HTMLElement;
        if (event.button === 1) {
          clickOnTarget(row, targetElement, true);
        }
      },
      sx: { cursor: "pointer" },
    }),
  };
};

export const TABLE_COLUMN_STYLE = {
  default: {
    enableHiding: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnActions: false,
  },
  onlySortable: {
    enableSorting: true,
    enableColumnFilter: false,
    enableHiding: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnActions: false,
  },
  emptyTableHeaderStyle: (id: string) => ({
    id: id,
    header: "",
    enableSorting: false,
    enableColumnFilter: false,
    enableHiding: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnActions: false,
  }),
};

export const defaultTableStyle = {
  enableHiding: false,
  enableDensityToggle: false,
  enableFullScreenToggle: false,
  enableGlobalFilter: true,
  enableGlobalFilterModes: true,
  enableColumnFilters: true,
  enableFilters: true,
  enableTopToolbar: false,
  muiTableHeadCellProps: {
    sx: {
      padding: "10px 5px 10px 15px",
      ...typographyExtraSmall,
      color: COLORS["text-secondary"],
      lineHeight: "1.5",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      padding: "5px 5px 5px 15px",
      ...typographySmaller,
      color: COLORS["text"],
    },
  },
  muiTablePaperProps: {
    sx: {},
  },
  muiFilterTextFieldProps: {
    sx: {
      fontSize: "10px",
    },
  },
};

export const useTableLocalizations = () => {
  const userSettings = useUserSettings();
  const tableLocale = (userSettings.uiLangCode || defaultLanguage) as SupportedLanguage;

  const customTableLocalization: MRT_Localization = useMemo(
    () => ({
      actions: t`Actions`,
      and: t`and`,
      cancel: t`Cancel`,
      changeFilterMode: t`Change filter mode`,
      changeSearchMode: t`Change search mode`,
      clearFilter: t`Clear filter`,
      clearSearch: t`Clear search`,
      clearSelection: t`Clear selection`,
      clearSort: t`Clear sort`,
      clickToCopy: t`Click to copy`,
      copy: t`Copy`,
      collapse: t`Collapse`,
      collapseAll: t`Collapse all`,
      columnActions: t`Column Actions`,
      copiedToClipboard: t`Copied to clipboard`,
      dropToGroupBy: t`Drop to group by {column}`,
      edit: t`Edit`,
      expand: t`Expand`,
      expandAll: t`Expand all`,
      filterArrIncludes: t`Includes`,
      filterArrIncludesAll: t`Includes all`,
      filterArrIncludesSome: t`Includes`,
      filterBetween: t`Between`,
      filterBetweenInclusive: t`Between Inclusive`,
      filterByColumn: t`Filter by {column}`,
      filterContains: t`Contains`,
      filterEmpty: t`Empty`,
      filterEndsWith: t`Ends With`,
      filterEquals: t`Equals`,
      filterEqualsString: t`Equals`,
      filterFuzzy: t`Fuzzy`,
      filterGreaterThan: t`Greater Than`,
      filterGreaterThanOrEqualTo: t`Greater Than Or Equal To`,
      filterInNumberRange: t`Between`,
      filterIncludesString: t`Contains`,
      filterIncludesStringSensitive: t`Contains`,
      filterLessThan: t`Less Than`,
      filterLessThanOrEqualTo: t`Less Than Or Equal To`,
      filterMode: t`Filter Mode: {filterType}`,
      filterNotEmpty: t`Not Empty`,
      filterNotEquals: t`Not Equals`,
      filterStartsWith: t`Starts With`,
      filterWeakEquals: t`Equals`,
      filteringByColumn: t`Filtering by {column} - {filterType} {filterValue}`,
      goToFirstPage: t`Go to first page`,
      goToLastPage: t`Go to last page`,
      goToNextPage: t`Go to next page`,
      goToPreviousPage: t`Go to previous page`,
      grab: t`Grab`,
      groupByColumn: t`Group by {column}`,
      groupedBy: t`Grouped by `,
      hideAll: t`Hide all`,
      hideColumn: t`Hide {column} column`,
      max: t`Max`,
      min: t`Min`,
      move: t`Move`,
      noRecordsToDisplay: t`No records to display`,
      noResultsFound: t`No results found`,
      of: t`of`,
      or: t`or`,
      pin: t`Pin`,
      pinToLeft: t`Pin to left`,
      pinToRight: t`Pin to right`,
      resetColumnSize: t`Reset column size`,
      resetOrder: t`Reset order`,
      rowActions: t`Row Actions`,
      rowNumber: t`#`,
      rowNumbers: t`Row Numbers`,
      rowsPerPage: t`Rows per page`,
      save: t`Save`,
      search: t`Search`,
      selectedCountOfRowCountRowsSelected: t`{selectedCount} of {rowCount} row(s) selected`,
      select: t`Select`,
      showAll: t`Show all`,
      showAllColumns: t`Show all columns`,
      showHideColumns: t`Show/Hide columns`,
      showHideFilters: t`Show/Hide filters`,
      showHideSearch: t`Show/Hide search`,
      sortByColumnAsc: t`Sort by {column} ascending`,
      sortByColumnDesc: t`Sort by {column} descending`,
      sortedByColumnAsc: t`Sorted by {column} ascending`,
      sortedByColumnDesc: t`Sorted by {column} descending`,
      thenBy: t`, then by `,
      toggleDensity: t`Toggle density`,
      toggleFullScreen: t`Toggle full screen`,
      toggleSelectAll: t`Toggle select all`,
      toggleSelectRow: t`Toggle select row`,
      toggleVisibility: t`Toggle visibility`,
      ungroupByColumn: t`Ungroup by {column}`,
      unpin: t`Unpin`,
      unpinAll: t`Unpin all`,
    }),
    [tableLocale],
  );

  const supportedTableLocalization: Record<SupportedLanguage, MRT_Localization> = useMemo(
    () => ({
      en: MRT_Localization_EN,
      es: MRT_Localization_ES,
      fr: MRT_Localization_FR,
      pl: MRT_Localization_PL,
      ru: MRT_Localization_RU,
      ar: MRT_Localization_AR,
      de: MRT_Localization_DE,
      id: MRT_Localization_ID,
      it: MRT_Localization_IT,
      ja: MRT_Localization_JA,
      ko: MRT_Localization_KO,
      ms: customTableLocalization,
      pt: MRT_Localization_PT,
      // th is Thai
      th: customTableLocalization,
      tr: MRT_Localization_TR,
      vi: MRT_Localization_VI,
      uk: MRT_Localization_UK,

      // zh is Chinese
      zh: MRT_Localization_ZH_HANS,
      //da -  Danish
      da: MRT_Localization_DA,

      // Norwegian
      nb: MRT_Localization_NO,

      // Swedish
      sv: MRT_Localization_SV,
    }),
    [customTableLocalization],
  );

  return {
    lang: tableLocale,
    tableLocalization: supportedTableLocalization[tableLocale],
  };
};
