import { FC } from "react";

import { Stack } from "@mui/material";

import { PageCard } from "../Cards/PageCard";
import { PageContainer } from "./PageContainer";
import { PageSizes } from "./styles";

interface CardPageContainerProps {
  pageSize?: PageSizes;
  children: React.ReactNode;
}

export const CardPageContainer: FC<CardPageContainerProps> = ({ pageSize = "small", children }) => {
  return (
    <Stack
      sx={{
        paddingBottom: "75px",
      }}
    >
      <PageContainer size={pageSize}>
        <PageCard>{children}</PageCard>
      </PageContainer>
    </Stack>
  );
};
