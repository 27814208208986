import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { doc, setDoc } from "firebase/firestore";
import { Flag, InfoCircle } from "iconsax-react";
import { FC, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { AccessForm } from "src/prohiring/AccessForm/AccessForm";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { Tabs } from "src/uiKit/Tabs/Tabs";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from "@mui/material";

import { CandidateAccess } from "@common/vacancy.types";

import { isColumnVisibleForClient } from "../Vacancy/lib/isColumnVisibleForClient";

interface AccessSettingsProps {
  vacancyId: string;
}
export const AccessSettings: FC<AccessSettingsProps> = ({ vacancyId }) => {
  const [vacancy] = useDocumentData(doc(db.collections.vacancies, vacancyId));

  const [selectedStepOnAccessSettings, setSelectedStepOnAccessSettings] = useState<string>("");
  const vacancySteps = vacancy?.hiringCircle.map((step) => step.title) || [];

  const vacancyAccessLevels = vacancy?.vacancyAccessLevels || [];
  const stepAccessLevels =
    vacancy?.hiringCircleAccessLevels?.[selectedStepOnAccessSettings] ||
    vacancy?.vacancyAccessLevels ||
    [];

  const [isVacancyAccessSettingsSaving, setIsVacancyAccessSettingsSaving] = useState(false);
  const setVacancyAccess = async (newAccessLevel: CandidateAccess[]) => {
    const document = doc(db.collections.vacancies, vacancyId);
    setIsVacancyAccessSettingsSaving(true);
    await setDoc(document, { vacancyAccessLevels: newAccessLevel }, { merge: true });
    setIsVacancyAccessSettingsSaving(false);
  };

  const [isStepAccessSettingsSaving, setIsStepAccessSettingsSaving] = useState(false);
  const setHiringCircleAccess = async (step: string, newAccess: CandidateAccess[]) => {
    if (!vacancy) return;

    const document = doc(db.collections.vacancies, vacancyId);
    setIsStepAccessSettingsSaving(true);
    await setDoc(
      document,
      { hiringCircleAccessLevels: { ...vacancy.hiringCircleAccessLevels, [step]: newAccess } },
      { merge: true },
    );
    setIsStepAccessSettingsSaving(false);
  };

  const changeHiringPipelineVisibility = (step: string, value: boolean) => {
    if (!vacancy) return;
    const newAccess = { ...vacancy.hiringPipelineVisibilityForClient };
    newAccess[step] = value;

    const document = doc(db.collections.vacancies, vacancyId);
    setDoc(document, { hiringPipelineVisibilityForClient: newAccess }, { merge: true });
  };

  const isStepHasCustomAccess = (step: string) => {
    if (vacancy?.hiringPipelineVisibilityForClient?.[step] === false) {
      return true;
    }

    const stepAccess = vacancy?.hiringCircleAccessLevels?.[step];

    if (!stepAccess) {
      return false;
    }

    const allCandidatesAccessMap: Record<CandidateAccess, boolean> = {
      name: true,
      contact: true,
      portfolio: true,
      companyName: true,
      cvOverview: true,
      survey: true,
    };
    const allCandidatesAccessList = Object.keys(allCandidatesAccessMap) as CandidateAccess[];

    const isAllCandidatesAccess = allCandidatesAccessList.every((access) => {
      return stepAccess.includes(access);
    });

    return !isAllCandidatesAccess;
  };

  const activeStepIndex = vacancySteps.findIndex((step) => step === selectedStepOnAccessSettings);
  const activeStep = vacancySteps[activeStepIndex > 0 ? activeStepIndex : 0];

  return (
    <Stack
      direction={"column"}
      gap={"30px"}
      sx={{
        width: "100%",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: "20px",
        }}
      >
        <Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
            <Typography variant="h4">{t`Default access settings`}</Typography>
            <AutoSaveIndicator
              isSaving={isVacancyAccessSettingsSaving || isStepAccessSettingsSaving}
            />
          </Stack>
          <Typography
            variant="caption"
            sx={{
              lineHeight: "1.2",
              color: COLORS["text-secondary"],
            }}
          >
            {t`Define what information clients can view about candidates for this vacancy.`}
          </Typography>
        </Stack>
        <AccessForm setAccessLevels={setVacancyAccess} accessLevels={vacancyAccessLevels} />
      </Stack>

      <Stack gap={"0px"}>
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h4">{t`Step access settings`}</Typography>
          <Tooltip
            title={
              <Typography variant="caption">
                <Trans>
                  Step access settings determine what clients can view about candidates at this
                  particular stage of the recruitment process. These settings take precedence over
                  vacancy-wide access settings. For even more granular control, you can set
                  individual access rules <b>for each candidate</b>, which will supersede these step
                  settings.
                </Trans>
              </Typography>
            }
          >
            <InfoCircle size="18" />
          </Tooltip>
        </Stack>

        <Stack
          sx={{
            marginLeft: "-40px",
          }}
        >
          <Tabs
            tabs={vacancySteps.map((step) => {
              return {
                id: step,
                label: step,
                icon: isStepHasCustomAccess(step) ? <Flag variant="Bold" /> : undefined,
              };
            })}
            showScrollButtons={false}
            activeIndex={activeStepIndex >= 0 ? activeStepIndex : 0}
            onChange={(label: string, id: string) => setSelectedStepOnAccessSettings(id as string)}
          />
        </Stack>

        {activeStep ? (
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isColumnVisibleForClient(vacancy, activeStep)}
                  onChange={(e) => {
                    changeHiringPipelineVisibility(activeStep, e.target.checked);
                  }}
                />
              }
              label={t`Column visible to Client`}
            />
            {isColumnVisibleForClient(vacancy, activeStep) && (
              <AccessForm
                setAccessLevels={(value) => setHiringCircleAccess(activeStep, value)}
                accessLevels={stepAccessLevels}
              />
            )}
          </Stack>
        ) : (
          <Typography variant="caption"></Typography>
        )}
      </Stack>
    </Stack>
  );
};
