import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";

import { Box, Button, Card, CardContent, Link, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { RequestTimeOffModal } from "./RequestTimeOffModal";
import { calculateTimeOfBalance } from "./calculateTimeOff";
import { defaultTimeOffSettings } from "./defaultTimeOffSettings";

interface TimeOffCardProps {
  variant?: "default" | "short" | "selectable";
  activeButtonStyle?: "contained" | "outlined" | "none";
}

export const TimeOffCard: React.FC<TimeOffCardProps> = ({
  variant = "default",
  activeButtonStyle = "outlined",
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState<number>(Date.now());
  const [infoDb] = useDocumentData(db.documents.config.timeOffSettings);
  const info = infoDb || defaultTimeOffSettings;
  const auth = useAuth();
  const { uid } = auth;
  const [timeOffList] = useCollection(db.collections.timeOff);

  const listOfTimeOff = useMemo(() => {
    return (
      timeOffList?.docs
        .map((document) => document.data())
        .filter((request) => request.createdBy === uid)
        ?.sort((a, b) => {
          return b.createdAt - a.createdAt;
        }) || []
    );
  }, [timeOffList, uid]);

  const myBalance = calculateTimeOfBalance({
    allUsersTimeOffs: listOfTimeOff,
    currentTimestamp: selectedBalance,
  });

  return (
    <>
      {isShowModal && <RequestTimeOffModal onClose={() => setIsShowModal(false)} />}
      <Card
        sx={{
          padding: variant === "selectable" ? "10px" : "20px",
          height: "100%",
          boxSizing: "border-box",
          backgroundColor: variant === "selectable" ? "rgba(0, 0, 0, 0.05)" : "white",
        }}
      >
        <CardContent sx={{ height: "100%", boxSizing: "border-box" }}>
          <Stack
            sx={{
              gap: "0px",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            {variant === "default" && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h3">{t`Time off`}</Typography>
                <Link href="/calendar" variant="body2">
                  {t`View Calendar`} &gt;
                </Link>
              </Box>
            )}

            {variant === "selectable" && (
              <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <Typography>{t`Balance:`}</Typography>
                <DatePicker
                  value={dayjs(selectedBalance)}
                  format="DD.MM.YYYY"
                  onChange={(value) => {
                    const timestamp = value?.valueOf() || 0;
                    setSelectedBalance(timestamp);
                  }}
                />
              </Stack>
            )}

            <Box
              display="flex"
              justifyContent="center"
              sx={{
                gap: "40px",
              }}
            >
              <Stack
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography align="center" variant="caption">
                  {t`Days used`}
                </Typography>
                <Typography align="center" variant="h2">
                  {myBalance.usedDaysToCurrentDate}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography align="center" variant="caption">
                  {t`Days available/Total`}
                </Typography>
                <Typography align="center" variant="h2">
                  {Math.max(0, info.totalDaysAvailable - myBalance.plannedUsedDays)}/
                  {info.totalDaysAvailable}
                </Typography>
              </Stack>
            </Box>

            {activeButtonStyle !== "none" && (
              <Button fullWidth variant={activeButtonStyle} onClick={() => setIsShowModal(true)}>
                {t`Request time off`}
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
