import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { useMessages } from "src/core/Messages/useMessages/useMessages";
import { Tabs } from "src/uiKit/Tabs/Tabs";
import { CardTitle } from "src/uiKit/Typography/CardTitle";

import ActionFeed from "./ActionFeed";
import MessagesFeed from "./MessagesFeed";
import { FeedContainer } from "./style";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface FeedProps {
  onClose?: () => void;
}
export function Feed({ onClose }: FeedProps): JSX.Element {
  const { myMessages, isNewMessage } = useMessages();
  const [activeTab, setActiveTab] = useState("actions");

  const unreadMessages = myMessages.filter((message) => isNewMessage(message)).length;
  const tabs = [
    { label: t`Actions`, id: "actions" },
    {
      label: t`Comments`,
      id: "comments",
      badgeCount: unreadMessages > 9 ? `9+` : unreadMessages ? `${unreadMessages}` : "",
    },
  ];

  return (
    <FeedContainer>
      <CardTitle>{t`Feed`}</CardTitle>

      <Tabs tabs={tabs} onChange={(_, id) => setActiveTab(id)} />
      {activeTab === "actions" && <ActionFeed onClose={onClose} />}
      {activeTab === "comments" && <MessagesFeed onClose={onClose} />}
    </FeedContainer>
  );
}

export default Feed;
