import { lazy } from "react";
import { Route } from "react-router-dom";

const CalendarPage = lazy(() => import("./CalendarPage"));

export const calendarPageRouter = (
  <>
    <Route path="/calendar" element={<CalendarPage />} />
  </>
);
