import { pxToRem } from "src/core/Utils/size";
import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

import { typographySmaller } from "../Typography/style";
import { COLORS } from "../assets/styles/colors";
import { INPUT_BORDER_RADIUS } from "./input.style";

const style: ComponentsOverrides["MuiOutlinedInput"] = {
  input: {
    display: "block !important",
    // don't forget to update: app/src/uiKit/Autocomplete/style.ts
    padding: "12px 16px",
    color: COLORS.text,
    ...typographySmaller,
    lineHeight: pxToRem(24),
    minHeight: pxToRem(24),
    "&::placeholder": {
      opacity: 1,
      color: COLORS["text-secondary"],
    },
    ":disabled": {
      borderColor: COLORS.border,
      color: COLORS["text-secondary"],
    },
  },
  multiline: {
    padding: 0,
  },
  root: {
    fontFamily: "var(--font-family)",
    borderRadius: INPUT_BORDER_RADIUS,

    "::-webkit-input-placeholder": {
      color: "red",
      overflow: "visible",
      opacity: "1!important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid #D8DEEA`,
      "&::placeholder": {
        overflow: "visible",
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid",
      borderColor: COLORS["primary"],
    },
    "&.Mui-hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS["text-secondary"],
    },
    "&.Mui-error": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: COLORS["error"],
      },
    },
  },
};

export const customOutlinedInput: GetTypeOfProperty<Components, "MuiOutlinedInput"> = {
  styleOverrides: style,
  defaultProps: {
    notched: false,
  },
};
