import { Context, createContext } from "react";

import { UserInfo } from "@common/user.types";

export interface UserInfoWithId extends UserInfo {
  id: string;
}

export interface UsersContext {
  users: Record<string, UserInfo | undefined>;
  usersEmails: Record<string, UserInfoWithId | undefined>;
  emails: string[];
  activeEmails: string[];
  recruitersEmails: string[];
  isActiveUser: boolean;
  loading: boolean;
}

export const usersContext: Context<UsersContext> = createContext<UsersContext>({
  users: {},
  usersEmails: {},
  emails: [],
  activeEmails: [],
  recruitersEmails: [],
  isActiveUser: true,
  loading: false,
});
