import { t } from "@lingui/core/macro";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { CustomModal } from "src/uiKit/Modal/CustomModal";
import { Tabs } from "src/uiKit/Tabs/Tabs";

import { Stack, Typography } from "@mui/material";

import { getFullVacancyTitle } from "../Vacancy/lib/getFullVacancyTitle";
import { isVacancyClient } from "../Vacancy/lib/isVacancyClient";
import { AccessSettings } from "./AccessSettings";
import { AiSearchSettings } from "./AiSearchSettings";
import { SourceSettings } from "./SourceSettings";
import { TagsSettings } from "./TagsSettings";
import { VacancySettingsTab, useVacancySettingsUI } from "./hooks/useVacancySettingsUI";

export const VacancySettings = () => {
  const vacancySettingsState = useVacancySettingsUI();

  const [vacancy] = useDocumentData(
    vacancySettingsState.vacancyId
      ? doc(db.collections.vacancies, vacancySettingsState.vacancyId)
      : null,
  );
  const vacancyTitle = vacancy ? getFullVacancyTitle(vacancy) : "";
  const auth = useAuth();
  const isClient = vacancy ? isVacancyClient(vacancy, auth.userInfo.email || "") : false;

  if (
    isClient ||
    !vacancySettingsState.vacancyId ||
    vacancySettingsState.openSettingsTabIndex === null
  ) {
    return <></>;
  }

  return (
    <CustomModal width="100vw" onClose={() => vacancySettingsState.closeSettings()}>
      <Stack
        sx={{
          padding: "0",
          width: "100%",
          boxSizing: "border-box",
          minHeight: "calc(100dvh - 50px)",
          "@media (max-width: 600px)": {},
          //maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Stack>
            <Typography variant="h3">{t`Vacancy Settings`}</Typography>
            <Typography variant="caption">{vacancyTitle}</Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Tabs
            tabs={vacancySettingsState.tabs}
            activeIndex={vacancySettingsState.openSettingsTabIndex}
            onChange={(label: string, id: string) =>
              vacancySettingsState.openSettings(
                id as VacancySettingsTab,
                vacancySettingsState.vacancyId,
              )
            }
          />
        </Stack>

        <Stack
          sx={{
            width: "100%",
          }}
        >
          {vacancySettingsState.openSettingsTabId === "access-settings" && (
            <AccessSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
          {vacancySettingsState.openSettingsTabId === "ai-search" && (
            <AiSearchSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
          {vacancySettingsState.openSettingsTabId === "source" && (
            <SourceSettings vacancyId={vacancySettingsState.vacancyId} />
          )}

          {vacancySettingsState.openSettingsTabId === "tags" && (
            <TagsSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
        </Stack>
      </Stack>
    </CustomModal>
  );
};
