import { useEffect } from "react";
import { appName } from "src/core/AppConfig/appConfig";

export const useBrowserTabTitle = (pageTitle: string) => {
  useEffect(() => {
    const prevPageTitle = document.title;
    document.title = `${pageTitle.trim()} - ${appName}`;
    return () => {
      document.title = prevPageTitle || appName;
    };
  }, [pageTitle]);
};
