/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable prefer-rest-params */
import { useEffect } from "react";
import { isDevEnvironment } from "src/core/Url/url";

export const useYandex = () => {
  useEffect(() => {
    const isProdPropeople = location.host.endsWith("propeople.dev");
    if (isDevEnvironment || !isProdPropeople) {
      return;
    }

    // Avoid injecting the script multiple times
    if (document.getElementById("yandex-metrika")) return;

    // Inject Yandex.Metrika script
    (function (m: any, e: Document, t: string, r: string, i: string, k?: any, a?: any) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      // @ts-expect-error hack
      m[i].l = 1 * new Date();
      for (let j = 0; j < e.scripts.length; j++) {
        if (e.scripts[j].src === r) return;
      }
      k = e.createElement(t);
      k.type = "text/javascript";
      k.async = true;
      k.src = r;
      k.id = "yandex-metrika";
      a = e.getElementsByTagName(t)[0];
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    // Init Metrika
    (window as any).ym?.(100661706, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    });
  }, []);
};
