import * as Sentry from "@sentry/react";
import { query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { companyName } from "src/core/Url/url";

import { UserInfo } from "@common/user.types";

import { UserInfoWithId, UsersContext } from "./usersContext";

const recruitersSystemRoles = ["admin", "recruiter", "owner"];

export function useProviderUsers(): UsersContext {
  // ID, userInfo
  const [users, setUsers] = useState<Record<string, UserInfo | undefined>>({});
  const [usersEmails, setUsersEmails] = useState<Record<string, UserInfoWithId | undefined>>({});
  const [isActiveUser, setIsActiveUser] = useState<boolean>(true);

  const [emails, setEmails] = useState<string[]>([]);
  const [activeEmails, setActiveEmails] = useState<string[]>([]);
  const [recruitersEmails, setRecruitersEmails] = useState<string[]>([]);

  const auth = useAuth();

  const [allUsers, loadingUsers] = useCollection(auth.uid ? query(db.collections.users) : null);
  const myEmail = auth.userInfo.email || "";

  useEffect(() => {
    if (!myEmail) {
      return;
    }

    Sentry.setUser({
      id: auth.uid,
      email: myEmail,
      username: users[auth.uid]?.fullName || "",
      domain: companyName || "",
    });
  }, [myEmail]);

  useEffect(() => {
    if (allUsers && !loadingUsers) {
      const emails: string[] = [];
      const activeEmailsInternal: string[] = [];
      const newRecruitersEmails: string[] = [];
      let users: { [id: string]: UserInfo } = {};
      let usersEmails: { [email: string]: UserInfoWithId } = {};

      allUsers.docs.forEach((userDoc) => {
        const userInfo = userDoc.data();
        const isActive = userInfo.isActive;
        const accessSystemRole = userInfo.accessSystemRole || "";

        if (recruitersSystemRoles.includes(accessSystemRole)) {
          newRecruitersEmails.push(userInfo.email);
        }
        const isMyProfile = myEmail === userInfo.email;
        if (isMyProfile) {
          setIsActiveUser(isActive);
        }

        emails.push(userInfo.email);

        if (isActive) {
          users = { ...users, [userDoc.id]: userInfo };
          const userInfoWithId: UserInfoWithId = { ...userInfo, id: userDoc.id };
          usersEmails = { ...usersEmails, [userInfo.email]: userInfoWithId };
          activeEmailsInternal.push(userInfo.email);
        }
      });

      setRecruitersEmails(newRecruitersEmails);
      setUsers(users);
      setEmails(emails);
      setUsersEmails(usersEmails);
      setActiveEmails(activeEmailsInternal);
    }
  }, [allUsers, loadingUsers]);

  return {
    isActiveUser,
    users,
    emails,
    activeEmails,
    recruitersEmails,
    loading: loadingUsers || !auth.uid || !allUsers || (!users[auth.uid] && !auth.isSuperAdmin),
    usersEmails,
  };
}
