import React, { FC } from "react";

import { PageContainerElement, PageSizes } from "./styles";

interface PageContainerProps {
  size?: PageSizes;
  children: React.ReactNode;
}

export const PageContainer: FC<PageContainerProps> = ({ children, size }) => {
  return <PageContainerElement size={size}>{children}</PageContainerElement>;
};
