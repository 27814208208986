import { t } from "@lingui/core/macro";
import { doc, setDoc } from "firebase/firestore";
import { FC, useRef, useState } from "react";
import { useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";

import { AI_SEARCH_COLUMN_TITLE } from "../VacancyCvBoard/constants";
import { useAiSearchColumnSettings } from "./hooks/useAiSearchColumnSettings";

interface AiSearchSettingsProps {
  vacancyId: string;
}
export const AiSearchSettings: FC<AiSearchSettingsProps> = ({ vacancyId }) => {
  const { isAiSearchHidden, setAiSearchHidden } = useAiSearchColumnSettings(vacancyId);

  const [vacancyData, loading] = useDocumentData(doc(db.collections.vacancies, vacancyId));
  const [shortSummaryValue, setShortSummaryValue] = useState("");
  const inputRef = useRef("");
  inputRef.current = shortSummaryValue;

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty || loading || !vacancyData) return;
    setShortSummaryValue(vacancyData?.aiData?.shortSummary || "");
  }, [vacancyData, loading]);

  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    if (!isDirty) return;

    setIsSaving(true);
    const document = doc(db.collections.vacancies, vacancyId);
    await setDoc(document, { aiData: { shortSummary: shortSummaryValue } }, { merge: true });
    if (inputRef.current === shortSummaryValue) {
      setIsDirty(false);
      setTimeout(() => setIsSaving(false), 1000);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isDirty) {
        save();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [shortSummaryValue]);

  const columnName = AI_SEARCH_COLUMN_TITLE;

  return (
    <Stack
      gap={"10px"}
      sx={{
        alignItems: "flex-start",
      }}
    >
      <Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h3">{t`Smart Search`}</Typography>
          <AutoSaveIndicator isSaving={isSaving} />
        </Stack>

        <Typography
          variant="caption"
          sx={{
            color: COLORS["text-secondary"],
            maxWidth: "760px",
          }}
        >
          {t`AI-powered feature that analyzes job positions, descriptions, and requirements to suggest relevant candidates from the database for each vacancy.`}
        </Typography>
      </Stack>

      <FormControlLabel
        checked={!isAiSearchHidden}
        onChange={() => setAiSearchHidden(!isAiSearchHidden)}
        control={<Checkbox />}
        label={t`Show ${columnName} column at vacancy board`}
      />
      <TextField
        sx={{
          maxWidth: "500px",
          width: "100%",
        }}
        label={t`Query`}
        onChange={(e) => {
          setIsDirty(true);
          setShortSummaryValue(e.target.value);
        }}
        value={shortSummaryValue}
        onBlur={save}
      />
    </Stack>
  );
};
