import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  connectAuthEmulator,
  getAuth,
  initializeAuth,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { companyName } from "../Url/url";

const isStorybook = `${process.env.STORYBOOK || ""}` === "true";

const isEmulator = isStorybook
  ? false
  : `${process.env.REACT_APP_FIREBASE_EMULATOR || ""}` === "true";
const jestWorkerId = isStorybook ? "" : `${process.env.JEST_WORKER_ID || ""}`;

const firebaseConfig = isStorybook
  ? {
      apiKey: `AIzaSyCLR6qLTlEVjetnWb9hZGrnrkxY4MQnh5w`,
      authDomain: `profolio-staging.firebaseapp.com`,
      databaseURL: `https://profolio-staging-default-rtdb.europe-west1.firebasedatabase.app`,
      projectId: `profolio-staging`,
      storageBucket: `profolio-staging.appspot.com`,
      messagingSenderId: `821617073129`,
      appId: `1:821617073129:web:dcc73d7ea38733b34fc978`,
      measurementId: `G-YMB4YVBG9F`,
    }
  : {
      apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
      authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
      databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
      projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
      storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
      messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
      appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
      measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
    };

const isUnitTestsEnv = !!jestWorkerId;
const app = initializeApp(firebaseConfig);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const firestore = isSafari
  ? getFirestore(app)
  : initializeFirestore(app, {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    });

const auth = isSafari
  ? getAuth(app)
  : initializeAuth(app, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: browserPopupRedirectResolver,
    });

const storage = getStorage(app);
const functions = getFunctions(app);

if (isEmulator) {
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectAuthEmulator(auth, `http://${companyName}.localhost:9099`, { disableWarnings: true });
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
} else if (!isUnitTestsEnv && !isStorybook) {
  getAnalytics(app);
  getPerformance(app);
}

export { auth, firestore, storage, functions };
