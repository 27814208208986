import { t } from "@lingui/core/macro";
import { Cake, Star } from "iconsax-react";
import React, { useMemo } from "react";
import { getAnniversaryInfo } from "src/core/Users/MyProfile/getAnniversaryInfo";
import { getBirthdayInfo } from "src/core/Users/MyProfile/getBirthdayInfo";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

interface CelebrationInfo {
  userId: string;
  name: string;
  photoURL?: string;
  date: string;
  type: "birthday" | "anniversary";
  yearsAnniversary?: number;
}

const CelebrationItem: React.FC<CelebrationInfo> = ({
  name,
  photoURL,
  date,
  type,
  yearsAnniversary,
}) => {
  const yearsMapLabels: Record<number, string> = {
    1: t`1 year anniversary!`,
    2: t`2 years anniversary!`,
    3: t`3 years anniversary!`,
    4: t`4 years anniversary!`,
    5: t`5 years anniversary!`,
    6: t`6 years anniversary!`,
    7: t`7 years anniversary!`,
    8: t`8 years anniversary!`,
    9: t`9 years anniversary!`,
    10: t`10 years anniversary!`,
  };

  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: "50px 1fr 30px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <ColorTextAvatar
        sx={{
          fontSize: "1.3rem",
          width: "36px",
          height: "36px",
        }}
        name={name}
        src={photoURL || ""}
      />
      <Stack>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="caption" color="text.secondary">
          {type === "birthday"
            ? date + " - " + t`Happy birthday!`
            : yearsAnniversary
              ? yearsMapLabels[yearsAnniversary] || t`${yearsAnniversary} years anniversary!`
              : t`Anniversary!`}
        </Typography>
      </Stack>
      <Box
        sx={{
          color: COLORS["neutral-7"],
        }}
      >
        {type === "birthday" ? <Cake size={"20px"} /> : <Star size={"20px"} variant="Bold" />}
      </Box>
    </Stack>
  );
};

const CelebrationsCard: React.FC = () => {
  const { users } = useUsers();
  const userCelebrations: CelebrationInfo[] = useMemo(() => {
    const infos: CelebrationInfo[] = [];
    Object.keys(users).forEach((userId) => {
      const userInfo = users[userId];
      if (!userInfo) return;
      const { birthDate, hireDate, photoURL } = userInfo;

      const userName = userInfo.fullName || userInfo.email.split("@")[0];

      const nextBirthdayInfo = birthDate ? getBirthdayInfo(birthDate) : null;
      if (nextBirthdayInfo && nextBirthdayInfo.days <= 3) {
        infos.push({
          userId: userId,
          name: userName,
          photoURL: photoURL || "",
          date: nextBirthdayInfo.humanDate,
          type: "birthday",
        });
      }

      const nextAnniversaryInfo = hireDate ? getAnniversaryInfo(hireDate) : null;
      if (nextAnniversaryInfo && nextAnniversaryInfo.days <= 3 && nextAnniversaryInfo.years > 0) {
        infos.push({
          userId: userId,
          name: userName,
          photoURL: photoURL || "",
          date: nextAnniversaryInfo.humanDate,
          type: "anniversary",
          yearsAnniversary: nextAnniversaryInfo.years,
        });
      }
    });

    return infos;
  }, [users]);

  return (
    <Card
      sx={{
        padding: "20px",
      }}
    >
      <CardContent>
        <Typography variant="h3">{t`Celebrations`}</Typography>
        <Stack
          sx={{
            padding: "40px 0",
            gap: "15px",
          }}
        >
          {userCelebrations.length === 0 && (
            <Typography variant="caption" color={COLORS["text-secondary"]} sx={{ opacity: 0.5 }}>
              {t`No upcoming celebrations`}
            </Typography>
          )}
          {userCelebrations.map((userInfo) => (
            <CelebrationItem key={userInfo.userId} {...userInfo} />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CelebrationsCard;
