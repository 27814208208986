import { GetTypeOfProperty } from "src/core/Utils/type.utils";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Components, ComponentsOverrides, ComponentsVariants } from "@mui/material";

import { textRegular } from "../Typography/style";

export const menuShadow = "0px 4px 20px rgba(0, 0, 0, 0.2)";
export const menuRadius = "12px";

const menuStyleOverrides: ComponentsOverrides["MuiMenu"] = {
  paper: {
    borderRadius: menuRadius,
    boxShadow: menuShadow,
    backgroundColor: COLORS["bg-card"],
    padding: "5px 0",
  },
};

const menuVariants: ComponentsVariants["MuiMenu"] = [
  {
    props: { variant: "menu" },
    style: {},
  },
];

export const customMenu: GetTypeOfProperty<Components, "MuiMenu"> = {
  styleOverrides: menuStyleOverrides,
  variants: menuVariants,
};

const MenuItemStyleOverrides: ComponentsOverrides["MuiMenuItem"] = {
  root: {
    padding: "8px 20px",
    ".MuiListItemIcon-root": {},
    ".MuiListItemText-primary": {
      ...textRegular,
      color: COLORS["neutral-9"],
    },
  },
};

export const customMenuItem: GetTypeOfProperty<Components, "MuiMenuItem"> = {
  styleOverrides: MenuItemStyleOverrides,
};
