import { lazy } from "react";
import { Route } from "react-router-dom";

const PeoplePage = lazy(() => import("./PeoplePage"));

export const peoplePageRouter = (
  <>
    <Route path="/people" element={<PeoplePage />} />
  </>
);
