import { FC, ReactNode } from "react";

import { Typography } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

export const PageTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      variant="h2"
      component="h2"
      sx={{
        color: COLORS.text,
        "@media (max-width: 600px)": {
          fontSize: "1.2rem",
        },
      }}
    >
      {children}
    </Typography>
  );
};

export const PageSubTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      variant="caption"
      component="span"
      sx={{
        color: COLORS.text,
        "@media (max-width: 600px)": {
          fontSize: "0.8rem",
        },
      }}
    >
      {children}
    </Typography>
  );
};
