import { t } from "@lingui/core/macro";
import { useNavigate } from "react-router-dom";
import { useConfig } from "src/core/CompanyInfo/hooks/useConfig/useConfig";

import { Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { errors } from "./errorData";
import { ErrorCode, ErrorMessage, ErrorPageComponent, ErrorWrapper, SupportMail } from "./style";

function ErrorPage({ code, message }: { code: string; message?: string }): JSX.Element {
  const navigate = useNavigate();
  const errorsMap = new Map(errors.map((e) => [e.code, { ...e }]));
  const { companyConfig, globalConfig } = useConfig();
  const ups = t`Ups...`;

  return (
    <ErrorPageComponent>
      <ErrorWrapper>
        <ErrorCode>{`${errorsMap.get(code)?.code || ups}`}</ErrorCode>
        <ErrorMessage>
          <Stack direction={"column"} gap="2rem">
            <Typography variant="h2" component="h2">
              {errorsMap.get(code)?.code
                ? `${errorsMap.get(code)?.code || ups} : ${errorsMap.get(code)?.message}`
                : ups}
            </Typography>
            <Typography variant="body2" component="p">
              {message || `${errorsMap.get(code)?.text || t`Sorry, somthing wrong!`}`}
            </Typography>
            <Typography variant="body2" component="p">
              {t`For technical support, please contact your local administrator`}
              <SupportMail href={`mailto:${companyConfig.adminEmail}`} variant="body2">
                {companyConfig.adminEmail}
              </SupportMail>
            </Typography>
            <Typography variant="body2" component="p">
              {t`In case your administrator is unavailable, please contact us for technical support`}
              <SupportMail href={`mailto:${globalConfig.supportEmail}`} variant="body2">
                {globalConfig.supportEmail}
              </SupportMail>
            </Typography>
          </Stack>
          {/* Add button `Back to main page' if error's code 500 and more*/}
          {Number(code) >= 500 && (
            <Button onClick={() => navigate("/")} variant={"outlined"}>
              {t`Back to main page`}
            </Button>
          )}
        </ErrorMessage>
      </ErrorWrapper>
    </ErrorPageComponent>
  );
}

export default ErrorPage;
