import { Stack } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

interface PageCardProps {
  children: React.ReactNode;
}

export const PageCard: React.FC<PageCardProps> = ({ children }) => {
  return (
    <Stack
      sx={{
        padding: "24px",
        boxSizing: "border-box",
        borderRadius: "16px",
        border: `1px solid ${COLORS.border}`,
        backgroundColor: COLORS["bg-card"],
        gap: "24px",
      }}
    >
      {children}
    </Stack>
  );
};
