import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import React from "react";
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Loader } from "src/uiKit/Loader/Loader";
import { ThemeContextProvider } from "src/uiKit/MuiTheme";
import { NotificationProvider } from "src/uiKit/Notification/useNotification";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { AppType } from "@common/appType";

import { AccessProvider } from "./core/Access/useAccess/AccessProvider";
import { useHotjar } from "./core/Analytics/useHotjar";
import { useYandex } from "./core/Analytics/useYandex";
import { appType } from "./core/AppConfig/appConfig";
import { authRouter } from "./core/Auth/Auth.router";
import { AuthProvider } from "./core/Auth/useAuth";
import { BackendProvider } from "./core/Backend/useBackend/BackendProvider";
import { ConfigProvider } from "./core/CompanyInfo/hooks/useConfig/ConfigProvider";
import { CoreSettingsProvider } from "./core/CompanyInfo/hooks/useCoreSettings/CoreSettingsProvider";
import { FeaturesSettingsProvider } from "./core/CompanyInfo/hooks/useFeaturesSettings/FeaturesSettingsProvider";
import { companyStructureRouter } from "./core/CompanyStructure/CompanyStructure.router";
import { CookiesBar } from "./core/CookiesBar/CookiesBar";
import { DevButton } from "./core/DevButton/DevButton";
import { documentListRouter } from "./core/Documents/Document.router";
import { Header } from "./core/Header/Header";
import { currentLanguage, initLanguages } from "./core/Language/lang";
import { MessagesProvider } from "./core/Messages/useMessages/MessagesProvider";
import { GlobalModals } from "./core/Modal/GlobalModals";
import { securitySettingsRouter } from "./core/SecuritySettings/SecuritySettings.router";
import { supportsListRouter } from "./core/Support/Support.router";
import { SupportButton } from "./core/Support/SupportButton/SupportButton";
import systemRolesRouter from "./core/SystemRoles/SystemRoles.router";
import { termsRouter } from "./core/Terms/Terms.router";
import { isDevEnvironment } from "./core/Url/url";
import { isExtendedUrl } from "./core/Url/url";
import { usageRouter } from "./core/Usage/Usage.router";
import { UsageProvider } from "./core/Usage/useUsage/UsageProvider";
import userRouter from "./core/Users/User.router";
import { UserSettingsProvider } from "./core/Users/hooks/useUserSettings";
import { UsersProvider } from "./core/Users/hooks/useUsers/UsersProvider";
import { portfolioDesignEditorRouter } from "./profolio/Portfolio/PortfolioDesignEditor/views/PortfolioDesignEditor.router";
import { portfolioEditorRouter } from "./profolio/Portfolio/PortfolioEditor/views/PortfolioEditor.router";
import { portfolioRouter } from "./profolio/Portfolio/views/Portfolio.router";
import { sharedLinksEditorRouter } from "./profolio/SharedLinks/SharedLinksEditor/SharedLinksEditor.router";
import { sharedLinksListRouter } from "./profolio/SharedLinks/SharedLinksList/SharedLinksList.router";
import { sharedLinksViewPageRouter } from "./profolio/SharedLinks/SharedLinksViewPage/SharedLinksViewPage.router";
import { showcaseEditorRouter } from "./profolio/Showcase/ShowCaseEditor/views/ShowcaseEditor.router";
import { showcaseListRouter } from "./profolio/Showcase/ShowcaseList/ShowcaseList.router";
import { CVEditorRouter } from "./prohiring/CVEditor/view/CVEditor.router";
import { cvRouter } from "./prohiring/CVListPage/view/CV.router";
import { CvPreviewProvider } from "./prohiring/Candidates/usePreviewCv/CvProvider";
import { clientCompanyRouter } from "./prohiring/ClientCompany/ClientCompany.router";
import { ClientCompaniesProvider } from "./prohiring/ClientCompany/hooks/useClientCompanies/ClientCompaniesProvider";
import { HiringFeedProvider } from "./prohiring/Dashboard/useHiringFeed/HiringFeedProvider";
import { globalAnalyticsRouter } from "./prohiring/GlobalAnalytics/GlobalAnalytics.router";
import { HiringPipelineRouter } from "./prohiring/HiringPipeline/HiringPipeline.router";
import { vacancyRouter } from "./prohiring/Vacancy/Vacancy.router";
import { VacancySettings } from "./prohiring/VacancySettings/VacancySettings";
import { calendarPageRouter } from "./propeople/Calendar/Calendar.router";
import DashboardPropeople from "./propeople/Dashboard/DashboardPropeople";
import { myInfoPageRouter } from "./propeople/MyInfo/MyInfo.router";
import { peoplePageRouter } from "./propeople/People/People.router";
import { timeOffRouter } from "./propeople/TimeOff/TimeOff.router";
import { DialogProvider } from "./uiKit/Dialog/useDialog";

const RedirectPage = lazy(() => import("./core/Redirect/RedirectPage"));
const ContactsPage = lazy(() => import("./core/Contacts/Contacts"));
const WelcomePage = lazy(() => import("./core/WelcomePage/WelcomePage"));
const ErrorPage = lazy(() => import("src/uiKit/ErrorPage/ErrorPage"));

const DashboardHiring = lazy(() => import("./prohiring/Dashboard/HiringDashboard"));
const DashboardProfolio = lazy(() => import("./profolio/Dashboard/DashboardProfolio"));

const CompanyInfoPage = lazy(() => import("./core/CompanyInfo/CompanyInfoPage"));
const DevInfo = lazy(() => import("./core/DevInfo/DevInfo"));
const pageTitleMap: Record<AppType, string> = {
  hiring: "Prohiring",
  profolio: "Profolio",
  knowledgeBase: "Knowledge Base",
  people: "Propeople",
};

const favIcons: Record<AppType, string> = {
  hiring: "/logoProHiring192.png",
  profolio: "/favicon.ico",
  knowledgeBase: "/",
  people: "/people/logo192x192.png",
};

const AppRouter = () => {
  const mainPageMap: Record<AppType, JSX.Element> = {
    hiring: <DashboardHiring />,
    profolio: <DashboardProfolio />,
    people: <DashboardPropeople />,
    knowledgeBase: <WelcomePage />,
  };
  const dashboardComponent = mainPageMap[appType];

  const previewPage = isExtendedUrl ? dashboardComponent : <WelcomePage />;

  document.title = pageTitleMap[appType];
  const favIcon = favIcons[appType];
  document.querySelector("link[rel*='icon']")?.setAttribute("href", favIcon);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={previewPage} />
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/settings/company-info" element={<CompanyInfoPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/git-summary" element={<DevInfo />} />
        {securitySettingsRouter}
        {globalAnalyticsRouter}
        {systemRolesRouter}
        {usageRouter}
        {HiringPipelineRouter}
        {supportsListRouter}
        {userRouter}
        {showcaseEditorRouter}
        {authRouter}
        {companyStructureRouter}
        {termsRouter}
        {portfolioRouter}
        {showcaseListRouter}
        {portfolioEditorRouter}
        {portfolioDesignEditorRouter}
        {cvRouter}
        {CVEditorRouter}
        {vacancyRouter}
        {clientCompanyRouter}
        {documentListRouter}
        {sharedLinksListRouter}
        {sharedLinksEditorRouter}
        {sharedLinksViewPageRouter}
        {timeOffRouter}
        {peoplePageRouter}
        {calendarPageRouter}
        {myInfoPageRouter}
        <Route path="*" element={<ErrorPage code={"404"} />} />
      </Routes>
    </Suspense>
  );
};

initLanguages();

export default function App(): JSX.Element {
  useHotjar();
  useYandex();

  return (
    <I18nProvider i18n={i18n}>
      <ThemeContextProvider>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={currentLanguage === "en" ? "en-gb" : currentLanguage || "en-gb"}
        >
          <NotificationProvider>
            <DialogProvider>
              <BrowserRouter>
                <BackendProvider>
                  <AuthProvider>
                    <ConfigProvider>
                      <CoreSettingsProvider>
                        <UserSettingsProvider>
                          <AccessProvider>
                            <UsersProvider>
                              <ClientCompaniesProvider>
                                <MessagesProvider>
                                  <CvPreviewProvider>
                                    <FeaturesSettingsProvider>
                                      <HiringFeedProvider>
                                        <UsageProvider>
                                          <Header />
                                          <div style={{ position: "relative" }}>
                                            <AppRouter />
                                          </div>
                                          {isDevEnvironment && <DevButton />}
                                          <SupportButton />
                                          <GlobalModals />
                                          <VacancySettings />
                                          <CookiesBar />
                                        </UsageProvider>
                                      </HiringFeedProvider>
                                    </FeaturesSettingsProvider>
                                  </CvPreviewProvider>
                                </MessagesProvider>
                              </ClientCompaniesProvider>
                            </UsersProvider>
                          </AccessProvider>
                        </UserSettingsProvider>
                      </CoreSettingsProvider>
                    </ConfigProvider>
                  </AuthProvider>
                </BackendProvider>
              </BrowserRouter>
            </DialogProvider>
          </NotificationProvider>
        </LocalizationProvider>
      </ThemeContextProvider>
    </I18nProvider>
  );
}
