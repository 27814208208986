import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { TEST_SELECTOR, getTestDataIdAttribute } from "src/core/Tests/selectors";
import { makeFileNameShorter } from "src/core/Utils/FileNameConverter";
import { isImageName } from "src/core/Utils/fileNameCheck";
import { COLORS } from "src/uiKit/assets/styles/colors";

import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { ImagePreview } from "../Dialog/ImagePreview";
import { FileInfo } from "./FilesList";
import { FileContainer, FileDeleteButton, FileIcon, FileName, ImgSmallPreview } from "./style";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface UploadedFileProps {
  fileInfo: FileInfo;
  onDelete?: (fileName: string) => void;
  uploadedAt?: number;
  onClick?: () => void;

  authorName?: string;
}

export const UploadedFile = ({
  fileInfo,
  onDelete,
  onClick,
  uploadedAt,
  authorName,
}: UploadedFileProps) => {
  const shortFileName = makeFileNameShorter(fileInfo.fullFileName);
  const [showPreviewUrl, setShowPreviewUrl] = useState<string>();

  const isImage = isImageName(fileInfo.fullFileName);
  const fileUrl = fileInfo.url;

  const isNeedToShowPreview = isImage && fileUrl;
  const uploadedAgo = uploadedAt ? dayjs(uploadedAt).fromNow() : "";
  const uploadedDate = uploadedAt ? dayjs(uploadedAt).format("DD.MM.YYYY HH:mm") : "";

  return (
    <>
      <FileContainer {...getTestDataIdAttribute(TEST_SELECTOR.components.fileUploader)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            cursor: onClick ? "pointer" : "default",
            borderRadius: "5px",
            padding: "10px",
            ":hover": {
              backgroundColor: COLORS["bg"],
              boxShadow: "0px 0px 0 1px rgba(0, 0, 0, 0.2)",
            },
          }}
          onClick={() => {
            if (isNeedToShowPreview) {
              setShowPreviewUrl(fileUrl);
            }
            onClick?.();
          }}
        >
          <FileIcon>
            {isNeedToShowPreview && (
              <ImgSmallPreview src={fileInfo.url} alt={fileInfo.fullFileName} />
            )}
            {!isNeedToShowPreview && <InsertDriveFileIcon />}
          </FileIcon>

          <FileName>
            <Typography variant="body2">{shortFileName}</Typography>
            {uploadedAgo && (
              <Typography variant="caption" color={COLORS["text-secondary"]}>
                {t`Uploaded`} {uploadedAgo} | {uploadedDate}
              </Typography>
            )}
            {authorName && (
              <Stack
                direction="row"
                alignItems={"center"}
                gap={"5px"}
                sx={{
                  marginLeft: "-5px",
                }}
              >
                <PersonOutlinedIcon sx={{ fontSize: "1rem" }} />
                <Typography variant="caption" color={COLORS["text-secondary"]}>
                  {authorName}
                </Typography>
              </Stack>
            )}
          </FileName>
        </Box>

        {onDelete && (
          <FileDeleteButton onClick={() => onDelete(fileInfo.fullFileName)}>
            <ClearIcon />
          </FileDeleteButton>
        )}

        {showPreviewUrl && (
          <ImagePreview images={[showPreviewUrl]} onClose={() => setShowPreviewUrl("")} />
        )}
      </FileContainer>
    </>
  );
};
