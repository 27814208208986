import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

export const INPUT_BORDER_RADIUS = "12px";

const style: ComponentsOverrides["MuiInputBase"] = {
  input: {
    "&:-webkit-autofill": {
      boxShadow: `inset 0 0 0 30px ${COLORS["bg"]}`,
      "& + button": {
        marginLeft: "-52px",
      },
    },
  },
  root: {
    backgroundColor: COLORS["text-contrast"],
    fontFamily: "var(--font-family)",
    "label + &": {
      marginTop: "20px",
    },
    ".MuiTablePagination-root label + &": {
      marginTop: "inherit",
    },
  },
};

export const customInput: GetTypeOfProperty<Components, "MuiInputBase"> = {
  styleOverrides: style,
};
