import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import { doc, setDoc } from "firebase/firestore";
import { FC, useState } from "react";
import db from "src/core/Backend/firestore";
import { useBackend } from "src/core/Backend/useBackend/useBackend";
import { companyName } from "src/core/Url/url";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Button, Card, CircularProgress, Stack, Typography } from "@mui/material";

import { Bill } from "@common/usage.types";

interface BillShortCardProps {
  id: string;
  bill: Bill;
  onDeleteBill?: () => void;
  isViewMode?: boolean;
}

export const BillShortCard: FC<BillShortCardProps> = ({ id, bill, onDeleteBill, isViewMode }) => {
  const backend = useBackend();
  const [receiptLoading, setReceiptLoading] = useState(false);

  const openReceipt = async () => {
    if (!bill.stripeId) {
      alert(t`No payment id`);
      return;
    }

    if (bill.receiptUrl) {
      window.open(bill.receiptUrl, "_blank");
      return;
    }

    setReceiptLoading(true);

    const dataAboutPayment = await backend.payments.getPaymentInfo({
      domainName: companyName || "",
      paymentId: bill.stripeId,
    });

    if (dataAboutPayment.error) {
      alert(dataAboutPayment.error);
    } else if (dataAboutPayment.receiptUrl) {
      window.open(dataAboutPayment.receiptUrl, "_blank");
      const uid = doc(db.collections.bills, id);
      await setDoc(uid, { receiptUrl: dataAboutPayment.receiptUrl }, { merge: true });
    }

    setReceiptLoading(false);
  };

  return (
    <Card
      sx={{
        width: "100%",
        gap: "10px",
        padding: "20px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Stack gap="5px">
          <Stack direction={"row"} gap={"5px"}>
            <Typography variant="body2" color={COLORS["text-secondary"]}>
              {dayjs(bill.startTimestamp).format("DD MMMM YYYY")}
            </Typography>
            <Typography variant="body2" color={COLORS["text-disabled"]}>
              -
            </Typography>
            <Typography variant="body2" color={COLORS["text-secondary"]}>
              {dayjs(bill.endTimestamp).format("DD MMMM YYYY")}
            </Typography>
          </Stack>

          <Stack gap={"0px"}>
            <Stack direction={"row"} gap={"8px"}>
              <Typography variant="body1" color={COLORS["text-label"]}>
                {t`Status:`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: bill.isPaid ? COLORS["success"] : COLORS["warning"],
                  fontWeight: 600,
                }}
              >
                {bill.isPaid ? "Paid" : "Unpaid"}
              </Typography>
            </Stack>

            <Typography variant="h2" color={COLORS["text-label"]}>
              ${bill.amount.toFixed(2)}
            </Typography>
          </Stack>
        </Stack>
        {onDeleteBill && (
          <Button variant="outlined" color="error" onClick={onDeleteBill}>
            {t`Delete`}
          </Button>
        )}
        {bill.isPaid ? (
          <Button disabled={receiptLoading} variant="outlined" onClick={() => openReceipt()}>
            {receiptLoading ? <CircularProgress size={24} /> : t`Open receipt`}
          </Button>
        ) : (
          <Button variant="contained" href={`/billing?tab=1&bill=${id}`} disabled={isViewMode}>
            {t`Pay $${bill.amount.toFixed(2)}`}
          </Button>
        )}
      </Stack>
    </Card>
  );
};
