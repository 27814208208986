import { t } from "@lingui/core/macro";
import { doc, setDoc } from "firebase/firestore";
import { Add } from "iconsax-react";
import { FC, useEffect, useRef, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Button, Stack, Typography } from "@mui/material";

import { CategoryInfo } from "@common/vacancy.types";

import { categoriesColorsOptions, defaultCategories } from "../Vacancy/data/defaultCategories";
import { CategoriesList } from "../VacancyCvBoard/CategoriesList";

interface TagsSettingsProps {
  vacancyId: string;
}
export const TagsSettings: FC<TagsSettingsProps> = ({ vacancyId }) => {
  const [vacancy, loading] = useDocumentData(doc(db.collections.vacancies, vacancyId));
  const [tags, setTags] = useState<CategoryInfo[]>([]);
  const tagsRef = useRef(tags);
  tagsRef.current = tags;
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const vacancyCategories =
    vacancy?.allCandidateCategories && vacancy?.allCandidateCategories.length > 0
      ? vacancy.allCandidateCategories
      : defaultCategories;

  useEffect(() => {
    if (isDirty || loading || !vacancy) return;
    setTags(vacancyCategories);
  }, [vacancy, loading]);

  const isDirtyData = () => {
    return JSON.stringify(tagsRef.current) !== JSON.stringify(vacancyCategories);
  };

  const save = async () => {
    if (!isDirtyData()) return;
    setIsSaving(true);

    const candidatesCategoriesSelected = vacancy?.candidatesCategoriesSelected || {};
    Object.keys(candidatesCategoriesSelected).forEach((cvId) => {
      const cvCategories = candidatesCategoriesSelected[cvId];
      const newCategories = cvCategories.filter((categoryId) =>
        tags.some((category) => category.id === categoryId),
      );
      candidatesCategoriesSelected[cvId] = newCategories;
    });

    const document = doc(db.collections.vacancies, vacancyId);
    await setDoc(
      document,
      { allCandidateCategories: tags, candidatesCategoriesSelected },
      { merge: true },
    );

    setIsDirty(isDirtyData());

    setTimeout(() => setIsSaving(false), 1000);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isDirty) {
        save();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [tags]);

  const getRandomColor = () => {
    return categoriesColorsOptions[Math.floor(Math.random() * categoriesColorsOptions.length)];
  };

  const addCategory = () => {
    const newCategoryId = `${Date.now()}`;
    const newCategories = [
      ...tags,
      {
        color: getRandomColor(),
        value: "",
        id: newCategoryId,
      },
    ];
    setTags(newCategories);
    setIsDirty(true);
  };

  const onRemoveCategory = (categoryId: string) => {
    const confirmMessage = t`This will remove this tag from all cards. There is no undo.`;
    const isConfirmed = window.confirm(confirmMessage);
    if (!isConfirmed) return;

    const newCategories = tags.filter((category) => category.id !== categoryId);

    setTags(newCategories);
    setIsDirty(true);
  };

  const onChangeCategoryColor = (categoryId: string, color: string) => {
    const newCategories = tags.map((category) =>
      category.id === categoryId ? { ...category, color } : category,
    );
    setTags(newCategories);
    setIsDirty(true);
  };

  const onChangeCategoryValue = (categoryId: string, value: string) => {
    const newCategories = tags.map((category) =>
      category.id === categoryId ? { ...category, value } : category,
    );
    setTags(newCategories);
    setIsDirty(true);
  };

  return (
    <Stack gap={"15px"}>
      <Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h3">{t`Candidate tags`}</Typography>
          <AutoSaveIndicator isSaving={isSaving} />
        </Stack>

        <Typography
          variant="caption"
          sx={{
            color: COLORS["text-secondary"],
          }}
        >
          {t`Tags are used to categorize candidates. You can add, remove and change colors of tags.`}
        </Typography>
      </Stack>
      <Stack
        gap="5px"
        sx={{
          alignItems: "flex-start",
        }}
      >
        <CategoriesList
          categories={tags}
          candidateCategoriesIds={[]}
          onSelectCategory={() => {}}
          onChangeCategoryColor={onChangeCategoryColor}
          onChangeCategoryValue={onChangeCategoryValue}
          onRemoveCategory={onRemoveCategory}
        />

        <Stack
          sx={{
            paddingTop: "10px",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="text"
            onClick={() => {
              addCategory();
            }}
          >
            {t`Add new`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
