/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable prefer-rest-params */
import { useEffect } from "react";
import { appType } from "src/core/AppConfig/appConfig";
import { isDevEnvironment } from "src/core/Url/url";

import { AppType } from "@common/appType";

export const useHotjar = () => {
  useEffect(() => {
    if (isDevEnvironment) {
      return;
    }

    (function (h: any, o: any, t: any, j: any) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };

      const settingsMap: Record<AppType, { hjid: number; hjsv: number }> = {
        hiring: { hjid: 3726749, hjsv: 6 },
        profolio: { hjid: 3726878, hjsv: 6 },
        knowledgeBase: { hjid: 3726878, hjsv: 6 },
        people: { hjid: 3726878, hjsv: 6 },
      };
      h._hjSettings = settingsMap[appType];

      const a = o.getElementsByTagName("head")[0];
      const r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }, []);
};
