import styled from "@emotion/styled";

import { Box, Tab, TabProps } from "@mui/material";

import { tabsTxtTypography } from "../Typography/style";
import { COLORS } from "../assets/styles/colors";

export const BoxContainer = styled(Box)({
  width: "100%",
  boxShadow: "2px 0 0 0 gba(0, 0, 0, 0.1)",
});

export const BoxWrapper = styled(Box)<{ borders: string }>({}, ({ borders }) => ({
  borderBottom: borders ? `1px solid ${COLORS.border}` : "none",
}));

interface WrappedTab extends TabProps {
  badge?: string;
  opacity?: string;
}
export const WrappedTab = styled(Tab)<WrappedTab>({}, ({ badge, opacity }) => ({
  ...tabsTxtTypography,
  color: COLORS["text-secondary"],
  opacity: opacity ? opacity : 1,
  textTransform: "none",
  maxHeight: "48px",
  minHeight: "48px",
  height: "48px",
  padding: `0 ${badge ? "25px" : "16px"} 0 16px`,
  "&.Mui-selected": {
    color: COLORS["primary"],
  },
  ":after": {
    backgroundColor: COLORS["error"],
    color: COLORS["text-contrast"],
    fontSize: "9px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    display: badge ? "flex" : "none",
    content: `"${badge}"`,
    position: "absolute",
    top: "18px",
    right: "3px",
  },
}));
