import { useLocalStorage } from "@uidotdev/usehooks";
import { useAccess } from "src/core/Access/useAccess/useAccess";
import { isStorybook } from "src/core/Url/url";

export const useAiSearchColumnSettings = (vacancyId: string) => {
  const access = useAccess();
  const [aiSearchStorage, setAiSearchStorage] = useLocalStorage<Record<string, boolean>>(
    "aiSearchHidden",
    {},
  );

  const setAiSearchHidden = (hidden: boolean) =>
    setAiSearchStorage((prevState) => ({ ...prevState, [vacancyId]: hidden }));

  return {
    isAiSearchHidden: isStorybook || !access.viewCvBase || aiSearchStorage[vacancyId],
    setAiSearchHidden,
  };
};
