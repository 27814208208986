import { FirebaseError } from "@firebase/util";
import { t } from "@lingui/core/macro";
import {
  AuthError,
  GoogleAuthProvider,
  ParsedToken,
  User,
  UserCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Context,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import db from "src/core/Backend/firestore";

import { Stack } from "@mui/material";

import { auth } from "../Backend";
import { getErrorMessageFirebase } from "../Backend/errorMessages";
import { uploadFile } from "../Backend/uploadFile";
import { useBackend } from "../Backend/useBackend/useBackend";
import { Header } from "../Header/Header";
import { companyName, getFullUrlWithDomain, getFullUrlWithDomainTrim } from "../Url/url";
import { useQuery } from "../Url/useQuery";
import { fromBase64, toBase64 } from "../Utils/base64";
import { sleep } from "../Utils/sleep";
import { urlToFile } from "../Utils/urlToFile";
import AuthPage from "./AuthPage";
import { ExtensionToken } from "./ExtensionToken";
import { LogOut } from "./LogOut";
import SignIn from "./SignIn/SignIn";

interface SignInResult {
  isDone: boolean;
  error: string;
}
export interface AuthContext {
  loading: boolean;
  isSuperAdmin: boolean;
  uid: string;
  userInfo: {
    displayName: string | null;
    email: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
  };
  user: User | null;
  isAuthorized: boolean;
  logout: () => Promise<void>;
  redirectToSignIn: () => Promise<void>;
  redirectToRootPage: () => Promise<void>;
  usersCompanies: string[];
  selectCompany: (domain: string) => Promise<void>;
  signInWithGoogle: () => Promise<SignInResult | void>;
  signInWithPassword: (email: string, password: string) => Promise<SignInResult | void>;
  getSelectedCompanyFromLocalStorage: () => string;
  setSelectedCompanyToLocalStorage: (company: string) => void;
}

export const authContext: Context<AuthContext> = createContext<AuthContext>({
  loading: true,
  usersCompanies: [],
  uid: "",
  isAuthorized: false,
  userInfo: {
    phoneNumber: "",
    email: "",
    photoURL: "",
    displayName: "",
  },
  user: null,
  redirectToSignIn: async () => void 0,
  redirectToRootPage: async () => void 0,
  logout: async () => void 0,
  isSuperAdmin: false,
  selectCompany: async () => void 0,
  signInWithGoogle: async () => ({ isDone: false, error: "" }),
  signInWithPassword: async () => ({ isDone: false, error: "" }),
  getSelectedCompanyFromLocalStorage: () => "",
  setSelectedCompanyToLocalStorage: () => void 0,
});

function useProvideAuth(): AuthContext {
  const [user, loading, errorAuth] = useAuthState(auth);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const backend = useBackend();
  const [usersCompanies, setUsersCompanies] = useState<string[]>([]);

  const redirectToBackUrl = async (): Promise<void> => {
    if (!companyName) return;
    const query = new URLSearchParams(window.location.search);
    const backQuery = query.get("back")?.trim() || "";
    if (!backQuery) {
      navigate("/");
    } else {
      const backUrl = fromBase64(backQuery);
      navigate(backUrl);
    }
  };

  const signInWithGoogle = async (): Promise<SignInResult | void> => {
    const provider = new GoogleAuthProvider();
    try {
      const credentials = await signInWithPopup(auth, provider);
      await afterLogin(credentials);
    } catch (error) {
      if (error instanceof FirebaseError && error.code === "auth/popup-closed-by-user") {
        return { isDone: false, error: "" };
      }
      console.error("Google sign in error", error);
      return {
        isDone: false,
        error: t`Google sign-in was unsuccessful. Please try again.`,
      };
    }
  };

  const signInWithPassword = async (
    email: string,
    password: string,
  ): Promise<SignInResult | void> => {
    try {
      const credentials = await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
      await afterLogin(credentials);
    } catch (error) {
      const code = "code" in (error as AuthError) ? (error as AuthError)?.code || "" : "";
      if (code) {
        const errorMessage = getErrorMessageFirebase(code);
        return {
          isDone: false,
          error: errorMessage,
        };
      }

      console.error("Sign in error", error);
      return {
        isDone: false,
        error: t`Sign-in was unsuccessful. Please try again.`,
      };
    }
  };

  const initByServerTokenIdProcess = useRef(false);
  const [isProcessingTokenId, setIsProcessingTokenId] = useState(false);
  const initServerToken = async () => {
    if (initByServerTokenIdProcess.current || !companyName) {
      return;
    }

    const query = new URLSearchParams(window.location.search);
    const tokenId = query.get("tokenId") || "";
    if (!tokenId) {
      setIsProcessingTokenId(false);
      return;
    }

    initByServerTokenIdProcess.current = true;
    setIsProcessingTokenId(true);
    try {
      const tokenRequest = await backend.auth.temporaryToken({
        action: "get",
        tokenId: tokenId || "",
      });

      const token = "token" in tokenRequest ? tokenRequest.token : null;

      if (!token) {
        console.error("No token in backend");
        console.error(tokenRequest);
      } else {
        await signInWithCustomToken(auth, token);
        await sleep(300);
        redirectToBackUrl();
      }
    } finally {
      setIsProcessingTokenId(false);
    }
  };

  useEffect(() => {
    initServerToken();
  }, []);

  // redirect to company space
  const selectCompany = async (domain: string): Promise<void> => {
    console.log("selectCompany", domain);
    if (!domain) {
      console.log("selectCompany: No domain");
      return;
    }

    if (!auth.currentUser) {
      console.error("selectCompany: No user");
      return;
    }

    const token = await auth.currentUser.getIdToken(true);
    if (!token) {
      console.error("selectCompany: No token");
      return;
    }

    const tokenRequest = await backend.auth.temporaryToken({
      action: "set",
      domain: domain,
      token,
    });

    const tokenId = "tokenId" in tokenRequest ? tokenRequest.tokenId : null;
    if (!tokenId) {
      alert("Error: Unable to sign in. Please check your credentials and try again.");
      console.error("No token in backend", tokenRequest);
      return;
    }
    const searchParam = new URLSearchParams(location.search);
    const backParam = searchParam.get("back") || "";
    const isExtension = !!searchParam.get("extension");

    const urlToRedirect = isExtension
      ? getFullUrlWithDomainTrim("auth") +
        `/extension-token?tokenId=${tokenId}&companyForExtension=${domain}`
      : getFullUrlWithDomain(domain) + "sign-in?tokenId=" + tokenId + "&back=" + backParam;

    window.location.href = urlToRedirect;
  };

  const query = useQuery();
  const isExtension = query.get("extension");

  const localStorageKey = "selectedCompany_signIn";
  const getSelectedCompanyFromLocalStorage = () => {
    const company = localStorage.getItem(localStorageKey);
    return company || "";
  };

  const setSelectedCompanyToLocalStorage = (company: string) => {
    localStorage.setItem(localStorageKey, company);
  };

  const afterLogin = async (credentials: UserCredential) => {
    const tokenInfo = await credentials.user.getIdTokenResult();
    const claims = tokenInfo.claims as ParsedToken;
    const userDomains = claims?.domains as string[] | null;
    if (!userDomains) {
      console.error("User has no companies");
      return;
    }

    if (userDomains.length === 0) {
      console.error("User has no companies");
      return;
    }

    setUsersCompanies(userDomains);

    // auth space
    const searchParam = new URLSearchParams(location.search);
    const domainNameFromUrl = searchParam.get("company") || "";

    // CASE: user has only one company. no url param
    if (!domainNameFromUrl && userDomains.length === 1) {
      await selectCompany(userDomains[0]);
      return;
    }

    if (domainNameFromUrl) {
      const isDomainFromUrlInList = userDomains.includes(domainNameFromUrl);
      if (isDomainFromUrlInList) {
        await selectCompany(domainNameFromUrl);
        return;
      } else {
        console.error("Domain from url is not in list");
        return;
      }
    }

    const companyFromStorage = getSelectedCompanyFromLocalStorage();
    const isCompanyFromStorageInList = userDomains.includes(companyFromStorage);
    if (companyFromStorage && isCompanyFromStorageInList && isExtension) {
      await selectCompany(companyFromStorage);
      return;
    }
  };

  useEffect(() => {
    if (location.pathname === "/logout" || !user) {
      return;
    }

    user.getIdTokenResult().then(async ({ claims }) => {
      const isSuperAdmin = !!claims?.isSuperAdmin;
      setIsSuperAdmin(isSuperAdmin);
      if (isSuperAdmin) return;

      const userDomains = claims?.domains as string[] | null;
      if (!userDomains) {
        await auth.signOut();
        alert("Error: User not found. Please, sign in with correct credentials.");
        return;
      }

      if (companyName) {
        updateProfile(user.uid);
        return;
      }

      setUsersCompanies(userDomains);
    });
  }, [user, user?.uid]);

  const navigate = useNavigate();
  const isAuthorized = !!user?.uid && !errorAuth;

  const userInfo = useMemo(
    () => ({
      email: user?.email || "",
      photoURL: user?.photoURL || "",
      displayName: user?.displayName || "User",
      uid: user?.uid || "",
      phoneNumber: user?.phoneNumber || "",
      providerId: user?.providerId || "",
    }),
    [user],
  );

  const updateUserPhoto = async (userId: string): Promise<void> => {
    if (!user?.photoURL) {
      return;
    }

    const userDoc = doc(db.collections.users, userId);
    const profileInfo = await getDoc(userDoc);
    let photoUrl = profileInfo.data()?.photoURL;
    if (photoUrl === null || photoUrl) {
      return;
    }

    const file = await urlToFile(user.photoURL, `${userId}.png`, "image/png");
    const filePathInStorage = `${companyName}/avatars/${userId}/${Date.now()}.png`;
    photoUrl = await uploadFile(filePathInStorage, file);
    if (!photoUrl) {
      return;
    }
    await updateDoc(userDoc, { photoURL: photoUrl });
  };

  const updateProfile = async (userId: string): Promise<void> => {
    setTimeout(async () => {
      const time = Date.now();
      const userDoc = doc(db.collections.users, userId);
      const lastLoginCompanyName = companyName || "";
      updateUserPhoto(userId);

      await updateDoc(userDoc, { lastLoginAt: time, lastLoginCompanyName });
    }, 500);
  };

  const logout = async (): Promise<void> => {
    await auth.signOut();
    await redirectToSignIn();
  };

  const isRedirecting = useRef(false);

  const redirectToSignIn = async (): Promise<void> => {
    if (isRedirecting.current) {
      return;
    }
    isRedirecting.current = true;

    if (companyName) {
      const fullPath = window.location.pathname + window.location.search;
      const backParam =
        fullPath.includes("/sign-in") || fullPath === "/" ? "" : `${toBase64(fullPath)}`;

      const signInLink =
        getFullUrlWithDomainTrim("auth") + `/logout?back=${backParam}&company=${companyName}`;

      await sleep(100);
      window.location.href = signInLink;
      return;
    } else {
      const searchQuery = new URLSearchParams(location.search);
      const backParam = searchQuery.get("back") || "";
      const companyNameFromUrl = searchQuery.get("company") || "";
      const searchParams = `/sign-in?back=${backParam || ""}&company=${companyNameFromUrl}`;
      navigate(searchParams);
    }
  };
  const redirectToRootPage = async (): Promise<void> => navigate("/");

  return {
    setSelectedCompanyToLocalStorage,
    getSelectedCompanyFromLocalStorage,
    signInWithGoogle,
    signInWithPassword,
    isSuperAdmin,
    isAuthorized: isAuthorized,
    userInfo,
    uid: user?.uid || "",
    loading: loading || isProcessingTokenId,
    redirectToSignIn,
    redirectToRootPage,
    logout,
    user: user || null,
    usersCompanies,
    selectCompany,
  };
}

export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useProvideAuth();
  const location = useLocation();

  const staticPages = [
    "/contacts",
    "/parser/public",
    "/examples",
    "/redirect",
    "/terms-of-service",
    "/privacy-policy",
    "/portfolio/",
    "/git-summary",
    "/sharedLink/",
    "/logout",
    "/extension-token",
  ];
  const authPages = [
    "/sign-in",
    "/sign-up-create-account",
    "/reset-password",
    "/request-reset-password",
    "/confirm-company",
    "/accept-invite",
  ];

  const pathname = location.pathname;
  const isStaticPage = staticPages.find((path) => pathname.startsWith(path));
  const isAuthPage = authPages.find((path) => pathname.startsWith(path));

  const needToRedirectToAuthPage = !isAuthPage && !isStaticPage;

  useEffect(() => {
    if (!auth.loading && !auth.isAuthorized && needToRedirectToAuthPage) {
      auth.redirectToSignIn();
    }
  }, [isStaticPage, isAuthPage, auth.isAuthorized, auth.loading]);

  if (auth.loading) {
    return <></>;
  }

  const componentForShow = companyName ? (
    props.children
  ) : isAuthPage ? (
    props.children
  ) : (
    <Stack>
      <Header />
      <div style={{ position: "relative" }}>
        {pathname === "/logout" ? (
          <LogOut />
        ) : pathname === "/extension-token" ? (
          <ExtensionToken />
        ) : (
          <AuthPage formComponent={<SignIn />} />
        )}
      </div>
    </Stack>
  );

  return <authContext.Provider value={auth}>{componentForShow}</authContext.Provider>;
}

export const useAuth = (): AuthContext => useContext(authContext);
