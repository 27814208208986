import { t } from "@lingui/core/macro";
import dayjs from "dayjs";

export function getAnniversaryInfo(timestamp: number): {
  humanDate: string;
  days: number;
  years: number;
  human: string;
} {
  const today = dayjs();
  const startDate = dayjs(timestamp);

  const humanDate = startDate.format("D MMMM");

  // Calculate how many years have passed since the original date
  const years = today.diff(startDate, "year");

  // Calculate the next anniversary date
  let nextAnniversary = startDate.add(years, "year");
  if (nextAnniversary.isBefore(today, "day")) {
    nextAnniversary = nextAnniversary.add(1, "year");
  }

  const diffDays = nextAnniversary.startOf("day").diff(today.startOf("day"), "day");

  let human = t`In ${nextAnniversary.from(today, true)}`;
  if (diffDays === 0) human = t`Today 🎉`;
  else if (diffDays === 1) human = t`Tomorrow`;

  return {
    humanDate,
    days: diffDays,
    years: nextAnniversary.diff(startDate, "year"),
    human,
  };
}
