import { Vacancy } from "@common/vacancy.types";

export const getVacancyClientEmails = (vacancy: Vacancy) => {
  const vacancyType = vacancy.clientType;
  const responsibleEmails =
    vacancyType === "Internal"
      ? vacancy.responsibleInternalCustomersEmails || []
      : vacancy.responsibleCustomersEmails || [];

  return responsibleEmails;
};
