import { t } from "@lingui/core/macro";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { ChangeEvent } from "react";
import { useState } from "react";

import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useDialog } from "../Dialog/useDialog";
import {
  StackButtonContainer,
  StyledDelete,
  StyledIconButton,
  StyledPhoto,
  StyledTextField,
} from "./style";

interface ImageProps {
  onChange: (file: File | null) => void;
  imageUrl: string | null;
  size: string;
}

export function ImageForm({ onChange, imageUrl, size }: ImageProps) {
  const { closeDialog, showDialog } = useDialog();
  const [showFormatLimitModal, setShowFormatLimitModal] = useState(false);
  const validImageFormats = ["jpg", "jpeg", "png", "svg"];
  const validImageFormatsLabel = validImageFormats.map((ext) => `.${ext}`).join(" ");

  const onFileUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    if (validateFileType(file.name)) {
      onChange(file);
    } else {
      setShowFormatLimitModal(true);
    }
  };

  const validateFileType = (fileName: string): boolean => {
    const extensionFile = fileName.split(".").pop()?.toLowerCase() ?? "";
    return validImageFormats.includes(extensionFile);
  };

  const onPhotoDelete = () => {
    onChange(null);
  };

  const displayConfirmDialog = () => {
    return new Promise((resolve) => {
      const closeHandlerWhenClickOutside = () => resolve(false);

      showDialog({
        title: "Delete Photo",
        closeHandler: closeHandlerWhenClickOutside,
        content: (
          <Box>
            <Typography variant="body1" component="p">
              {t`Are you sure you want to delete this photo?`}
            </Typography>
            <StackButtonContainer>
              <Button
                variant="contained"
                onClick={() => {
                  onPhotoDelete();
                  resolve(true);
                  closeDialog();
                }}
              >
                {t`Delete`}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  closeDialog();
                  resolve(false);
                }}
              >
                {t`Cancel`}
              </Button>
            </StackButtonContainer>
          </Box>
        ),
      });
    });
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <StyledTextField
              type={"file"}
              id="select-image"
              onChange={onFileUpdate}
              inputProps={{
                accept: "image/jpg, image/jpeg, image/svg, image/png",
              }}
            />
            <label htmlFor={!imageUrl ? "select-image" : ""}>
              <StyledIconButton component={"span"} {...bindTrigger(popupState)}>
                <Avatar
                  draggable="false"
                  src={imageUrl ? imageUrl : undefined}
                  alt="User photo"
                  sx={{
                    width: size,
                    height: size,
                  }}
                />
              </StyledIconButton>
            </label>
            {imageUrl && (
              <Menu {...bindMenu(popupState)}>
                <label htmlFor="select-image">
                  <MenuItem onClick={popupState.close}>
                    <StyledPhoto />
                    {t`Change Photo`}
                  </MenuItem>
                </label>
                <MenuItem onClick={displayConfirmDialog}>
                  <StyledDelete />
                  {t`Delete Photo`}
                </MenuItem>
              </Menu>
            )}
          </>
        )}
      </PopupState>
      <ConfirmModal
        isOpen={!!showFormatLimitModal}
        title={t`Please select proper format`}
        content={
          <Typography variant="body1" component="p">
            {t`The valid formats for an uploaded photo are ${validImageFormatsLabel}`}
          </Typography>
        }
        onConfirm={() => setShowFormatLimitModal(false)}
        confirmButtonTitle={t`Got it`}
        onCancel={() => setShowFormatLimitModal(false)}
      />
    </>
  );
}
