import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { DAY } from "src/core/Utils/time";
import { useNotification } from "src/uiKit/Notification/useNotification";
import { TextAreaInput } from "src/uiKit/TextAreaInput/TextAreaInput";

import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { TimeOff } from "@common/timeOff.types";

import { TimeOffCard } from "./TimeOffCard";
import { calculateTimeOffBalance } from "./calculateTimeOff";
import { defaultTimeOffSettings } from "./defaultTimeOffSettings";

interface RequestTimeOffFormProps {
  onClose: () => void;
}
export const RequestTimeOffForm: React.FC<RequestTimeOffFormProps> = ({ onClose }) => {
  const auth = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [infoDb] = useDocumentData(db.documents.config.timeOffSettings);
  const timeOffSettings = infoDb || defaultTimeOffSettings;
  const timeOffCategories = timeOffSettings.categories.filter((category) => category.title.trim());

  const notification = useNotification();
  const [request, setRequest] = useState<TimeOff>({
    id: "",
    from: Date.now(),
    to: Date.now() + 14 * DAY,
    description: "",
    submittedBy: "",
    submittedAt: Date.now(),
    status: "pending",
    approvedUserId: "",
    history: [],
    updatedAt: Date.now(),
    updatedBy: "",
    createdAt: Date.now(),
    createdBy: "",
    userId: "",
    timeOffCategoryId: timeOffSettings.defaultCategoryId,
  });

  useEffect(() => {
    setRequest((prev) => ({
      ...prev,
      timeOffCategoryId: timeOffSettings.defaultCategoryId,
    }));
  }, [timeOffSettings.defaultCategoryId]);

  const onConfirm = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const uid = doc(db.collections.timeOff);
    const processedRequest: TimeOff = {
      userId: auth.uid || "",
      createdBy: auth.uid || "",
      createdAt: Date.now(),
      updatedBy: auth.uid || "",
      updatedAt: Date.now(),
      submittedBy: auth.uid || "",
      submittedAt: Date.now(),
      from: request.from,
      timeOffCategoryId: request.timeOffCategoryId,
      to: request.to,
      description: request.description,
      status: "pending",
      approvedUserId: auth.uid || "",
      history: [
        {
          status: "pending",
          updatedAt: Date.now(),
          updatedBy: auth.uid || "",
          note: request.description,
        },
      ],
      id: uid.id,
    };

    await setDoc(uid, processedRequest);

    setIsSubmitting(false);
    notification.showNotification({
      type: "success",
      message: t`Request sent successfully`,
    });

    onClose();
  };

  const diffDetails = calculateTimeOffBalance({
    startTimeStamp: request.from,
    endTimeStamp: request.to,
    ranges: [
      {
        from: request.from,
        to: request.to,
      },
    ],
  });

  return (
    <Stack
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        onConfirm();
      }}
      sx={{
        gap: "40px",
      }}
    >
      <Typography variant="h3">Request time off</Typography>
      <Stack
        sx={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "3fr 2fr",
          gap: "70px",
        }}
      >
        <Stack gap={"20px"}>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <DatePicker
              label={t`From`}
              value={dayjs(request.from)}
              format="DD.MM.YYYY"
              maxDate={dayjs(request.to)}
              onChange={(value) => {
                const timestamp = value?.valueOf() || 0;
                setRequest((prev) => ({
                  ...prev,
                  from: timestamp,
                }));
              }}
            />
            <DatePicker
              label={t`To`}
              value={dayjs(request.to)}
              minDate={dayjs(request.from)}
              format="DD.MM.YYYY"
              onChange={(value) => {
                const timestamp = value?.valueOf() || 0;
                setRequest((prev) => ({
                  ...prev,
                  to: timestamp,
                }));
              }}
            />
          </Stack>

          <TextField
            select
            label={t`Time off category`}
            name="role"
            value={request.timeOffCategoryId}
            onChange={(e) => {
              setRequest((prev) => ({
                ...prev,
                timeOffCategoryId: e.target.value,
              }));
            }}
          >
            {timeOffCategories.map((category) => (
              <MenuItem value={category.id} key={category.id}>
                {category.title}
              </MenuItem>
            ))}
            <MenuItem value={""}>-</MenuItem>
          </TextField>

          <TextAreaInput
            value={request.description}
            onChange={(value) => {
              setRequest((prev) => ({
                ...prev,
                description: value,
              }));
            }}
            label={t`Note`}
          />
        </Stack>
        <Stack
          sx={{
            height: "200px",
          }}
        >
          <TimeOffCard variant="selectable" activeButtonStyle="none" />
        </Stack>
      </Stack>

      <Stack sx={{ gap: "10px" }}>
        <Typography variant="h5">{t`Total time off: ${diffDetails.workDayCount} days`}</Typography>
        <Stack
          sx={{
            gap: "20px",
            flexDirection: "row",
          }}
        >
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send request"}
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
