import { t } from "@lingui/core/macro";
import { FC } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { CandidateAccess } from "@common/vacancy.types";

interface AccessFormProps {
  accessLevels: CandidateAccess[];
  setAccessLevels: (value: CandidateAccess[]) => void;
}

export const AccessForm: FC<AccessFormProps> = ({ accessLevels, setAccessLevels }) => {
  const toggleVacancyAccess = (value: CandidateAccess) => {
    const newAccessLevels = accessLevels.includes(value)
      ? accessLevels.filter((level) => level !== value)
      : [...accessLevels, value];
    setAccessLevels(newAccessLevels);
  };

  const isFullAccess = accessLevels.length === 6;
  const isLimitedAccess = accessLevels.length === 0;

  type AccessLevelType = "full" | "limited" | "custom" | null;

  const accessLevel: AccessLevelType = isFullAccess
    ? "full"
    : isLimitedAccess
      ? "limited"
      : "custom";

  const setFullAccess = () => {
    setAccessLevels(["name", "survey", "contact", "portfolio", "companyName", "cvOverview"]);
  };

  const setLimitedAccess = () => {
    setAccessLevels([]);
  };

  const setAccessLevel = (value: AccessLevelType) => {
    switch (value) {
      case "full":
        setFullAccess();
        break;
      case "limited":
        setLimitedAccess();
        break;
      case "custom":
        break;
      default:
        break;
    }
  };

  return (
    <Stack sx={{}}>
      <ToggleButtonGroup
        color="primary"
        exclusive
        size="small"
        value={accessLevel}
        onChange={(e, value) => {
          value && setAccessLevel(value);
        }}
      >
        <ToggleButton value="full">{t`Full`}</ToggleButton>
        <ToggleButton value="limited">{t`Limited`}</ToggleButton>
      </ToggleButtonGroup>

      <FormGroup
        sx={{
          paddingLeft: "3px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("name")}
              onChange={() => toggleVacancyAccess("name")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`Candidate name`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("contact")}
              onChange={() => toggleVacancyAccess("contact")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`Contacts`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("survey")}
              onChange={() => toggleVacancyAccess("survey")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`Survey answers`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("portfolio")}
              onChange={() => toggleVacancyAccess("portfolio")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`Candidate links`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("cvOverview")}
              onChange={() => toggleVacancyAccess("cvOverview")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`CV overview`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accessLevels.includes("companyName")}
              onChange={() => toggleVacancyAccess("companyName")}
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            />
          }
          label={t`Work experience company name`}
        />
      </FormGroup>
    </Stack>
  );
};
