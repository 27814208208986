import { Trans } from "@lingui/react/macro";
import { CardPageContainer } from "src/uiKit/Layouts/CardPageContainer";
import { Loader } from "src/uiKit/Loader/Loader";
import { PageSubTitle, PageTitle } from "src/uiKit/Typography/PageTitle";

import { Stack, Typography } from "@mui/material";

import { useQuery } from "../Url/useQuery";

export const ExtensionToken = () => {
  const query = useQuery();

  const tokenId = query.get("tokenId") || "";
  const company = query.get("companyForExtension") || "";

  return (
    <CardPageContainer>
      <Stack>
        <PageTitle>
          <Trans>Sign In</Trans>
        </PageTitle>
        <PageSubTitle>
          <Trans>Processing authentication</Trans>
        </PageSubTitle>
      </Stack>
      <Loader padding="0" />
      <Stack>
        {tokenId && (
          <Typography
            variant="caption"
            sx={{
              opacity: 0.01,
            }}
            id={"tokenId"}
          >
            {tokenId}
          </Typography>
        )}

        {company && (
          <Typography
            variant="caption"
            sx={{
              opacity: 0.01,
            }}
            id={"company"}
          >
            {company}
          </Typography>
        )}
      </Stack>
    </CardPageContainer>
  );
};
