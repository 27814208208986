import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components } from "@mui/material";

export const alertStyle: GetTypeOfProperty<Components, "MuiAlert"> = {
  styleOverrides: {
    root: {
      borderRadius: "12px",
    },
  },
};
