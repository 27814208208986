import { t } from "@lingui/core/macro";
import React from "react";
import { toBase64 } from "src/core/Utils/base64";
import { PasswordTextField } from "src/uiKit/Input/PasswordTextField";
import { Loader } from "src/uiKit/Loader/Loader";

import GoogleIcon from "@mui/icons-material/Google";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";

import { getTestDataIdAttribute } from "../../Tests/selectors";
import { AuthTitle } from "../style";

interface SignInProps {
  loading?: boolean;
  loginWithGoogle: () => void;
  email: string;
  setEmail: (email: string) => void;
  emailError: string;

  password: string;
  setPassword: (password: string) => void;
  passwordError: string;
  handleSubmit: (e: React.FormEvent) => void;
  googleSignInError?: string;
}

export function SignInForm({
  loginWithGoogle,
  loading,
  email,
  setEmail,
  emailError,
  password,
  setPassword,
  passwordError,
  handleSubmit,
  googleSignInError,
}: SignInProps) {
  const [usePassword, setUsePassword] = React.useState(false);
  return (
    <>
      <AuthTitle>{t`Sign In`}</AuthTitle>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Stack>
          <Button
            onClick={loginWithGoogle}
            startIcon={<GoogleIcon />}
            variant="outlined"
            disabled={loading}
          >
            {t`Sign in with Google`}
          </Button>
          {googleSignInError && (
            <Typography sx={{ color: "error.main", textAlign: "center" }} variant="caption">
              {googleSignInError}
            </Typography>
          )}
        </Stack>

        <Typography variant="caption" sx={{ textAlign: "center", opacity: 0.6 }}>
          {t`or`}
        </Typography>
        {!usePassword && (
          <Button onClick={() => setUsePassword(!usePassword)} variant="text" disabled={loading}>
            {t`Sign in with password`}
          </Button>
        )}

        {usePassword && (
          <Stack gap={"10px"} component={"form"} onSubmit={handleSubmit} name="auth-form">
            <TextField
              id="email"
              type="email"
              placeholder={t`Enter corporate email`}
              label={t`Email address`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
            />

            <PasswordTextField
              id="password"
              placeholder={t`Enter password`}
              label={t`Password`}
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
            />

            <Link
              href={`/request-reset-password/?emailBase64=${toBase64(email)}`}
              variant="caption"
            >
              {t`Forgot password?`}
            </Link>

            <Button
              variant="contained"
              type="submit"
              sx={{ marginTop: "0" }}
              {...getTestDataIdAttribute("auth-form-sign-in-button")}
            >
              {t`Sign In`}
            </Button>
          </Stack>
        )}

        {loading && <Loader padding="0" />}
      </Box>
    </>
  );
}
