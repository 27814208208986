import { pxToRem } from "src/core/Utils/size";
import { GetTypeOfProperty } from "src/core/Utils/type.utils";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Components, ComponentsOverrides } from "@mui/material";

export const typographyBodySmall = {
  fontSize: pxToRem(16),
  fontWeight: 300,
};
export const typographyBody = {
  fontSize: pxToRem(18),
  fontWeight: 300,
};

export const typographySmaller = {
  fontSize: pxToRem(14),
  fontWeight: 320,
  letterSpacing: "-0px",
  b: {
    fontWeight: 600,
  },
};

//Text Regular
export const textRegular = {
  fontSize: pxToRem(14),
  fontWeight: 400,
  lineHeight: "1.5",
};

export const typographyExtraSmall = {
  fontSize: pxToRem(11),
  fontWeight: 400,
};

export const tabsTxtTypography = {
  fontSize: pxToRem(14),
  lineHeight: "1.5",
  fontWeight: 400,
};

export const styleTypography: ComponentsOverrides["MuiTypography"] = {
  root: {
    fontFamily: "var(--font-family)",
    fontWeight: 300,
    b: {
      fontWeight: 450,
    },
  },
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 450,
  },
  h2: {
    fontSize: pxToRem(28),
    lineHeight: pxToRem(36),
    letterSpacing: "-0.2px",
    fontWeight: 450,
  },
  h3: {
    fontSize: pxToRem(24),
    fontWeight: 450,
  },
  h4: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(36),
    fontWeight: 450,
  },
  h5: {
    fontSize: pxToRem(18),
    fontWeight: 300,
  },
  h6: {
    fontSize: pxToRem(16),
    fontWeight: 300,
  },

  // Body/18px | base
  body1: {
    ...typographyBody,
  },
  // Body Small/16px | small
  body2: {
    ...typographyBodySmall,
  },
  // Body Smaller/14px | smaller
  caption: {
    ...typographySmaller,

    // Body Extra Small/12px | extra-small
    small: {
      ...typographyExtraSmall,
    },
  },
};

export const customTypography: GetTypeOfProperty<Components, "MuiTypography"> = {
  styleOverrides: styleTypography,
};

const styleLink: ComponentsOverrides["MuiLink"] = {
  root: {
    fontFamily: "var(--font-family)",
    color: COLORS["primary"],
    ...tabsTxtTypography,
    textDecoration: "none",
  },
};

export const customLink: GetTypeOfProperty<Components, "MuiLink"> = {
  styleOverrides: styleLink,
};
