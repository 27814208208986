import { lazy } from "react";
import { Route } from "react-router-dom";

const TimeOffPage = lazy(() => import("./TimeOffPage"));
const TimeOffSettingsPage = lazy(() => import("./TimeOffSettingsPage"));

export const timeOffRouter = (
  <>
    <Route path="/time-off" element={<TimeOffPage />} />
    <Route path="/time-off-settings" element={<TimeOffSettingsPage />} />
  </>
);
