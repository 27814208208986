import { TimeOffCompanySettings } from "@common/timeOff.types";

export const defaultTimeOffSettings: TimeOffCompanySettings = {
  totalDaysAvailable: 24,
  defaultCategoryId: "flexible-time-off",
  categories: [
    {
      title: "Flexible Time Off",
      description: "General time off",
      id: "flexible-time-off",
    },
    {
      title: "Sick Leave",
      description: "",
      id: "sick-leave",
    },
    {
      title: "Wellness Leave",
      description: "When you need a break",
      id: "wellness-leave",
    },
  ],
};
