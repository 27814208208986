import { Vacancy } from "@common/vacancy.types";

interface IsNeedReviewProps {
  vacancy: Vacancy;
  userId: string;
}
export const isNeedReview = ({ vacancy, userId }: IsNeedReviewProps) => {
  const isNeedReviewVacancy = !!vacancy.isNeedReview;

  const reviewersUsers = vacancy.reviewRequiredUserIds || [];
  const isIamReviewer = reviewersUsers.includes(userId);

  const rejectedUsers = vacancy.rejectedBy || [];
  const isIamRejected = rejectedUsers.find((user) => user.id === userId);
  const approvedUsers = vacancy.approvedBy || [];
  const isIamApproved = approvedUsers.find((user) => user.id === userId);

  const isNeedReviewFromMe =
    isNeedReviewVacancy && isIamReviewer && !isIamRejected && !isIamApproved;

  return isNeedReviewFromMe;
};
