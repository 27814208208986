import { t } from "@lingui/core/macro";
import { SearchNormal1 } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import { useBackend } from "src/core/Backend/useBackend/useBackend";
import { companyName } from "src/core/Url/url";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { COLORS } from "src/uiKit/assets/styles/colors";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { CVPreview } from "@common/cv.types";

import { useAccess } from "../Access/useAccess/useAccess";

interface CandidateRowProps {
  candidate: CVPreview;
  isLast: boolean;
  id: string;
}
export const CandidateRow = ({ candidate, isLast, id }: CandidateRowProps) => {
  return (
    <Stack
      sx={{
        padding: "10px",
        borderBottom: !isLast ? `1px solid ${COLORS.border}` : "none",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ColorTextAvatar src={candidate.photoUrl} name={candidate.name || candidate.email} />
          <Stack>
            <Stack direction={"row"} gap={"5px"} alignItems={"center"}>
              <Link
                target="_blank"
                href={`/cv-editor/${id}`}
                sx={{
                  lineHeight: "1.2",
                }}
              >
                {candidate.name}
              </Link>
              {candidate.linkedInAlias && (
                <IconButton
                  size="small"
                  target="_blank"
                  href={`https://www.linkedin.com/in/${candidate.linkedInAlias}`}
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: "1rem",
                      color: COLORS["primary"],
                    }}
                  />
                </IconButton>
              )}
            </Stack>
            <Typography
              sx={{
                lineHeight: "1.2",
              }}
              variant="caption"
            >
              {candidate.email}
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
              }}
              variant="caption"
            >
              {candidate.jobTitle}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface CvSearchResult {
  id: string;
  preview: CVPreview;
  searchText: string;
}

export const SearchHeaderIcon = () => {
  const [anchorSearch, setAnchorSearch] = useState<null | HTMLElement>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const backend = useBackend();
  const [isLoading, setIsLoading] = useState(false);

  const [queryInput, setQueryInput] = useState("");
  const access = useAccess();

  const [searchResults, setSearchResults] = useState<CvSearchResult[]>([]);
  const [searchResultsQuery, setSearchResultsQuery] = useState<string>("");

  const runSearch = async () => {
    setIsLoading(true);
    const request = await backend.cv.cvTextSearch({
      searchQuery: queryInput,
      domainName: companyName || "",
      limit: 100,
    });

    const newSearchResults = request.searchResults.map((cv) => ({
      id: cv.cvId,
      preview: cv.preview,
      searchText: cv.description,
    }));
    setSearchResultsQuery(queryInput);
    setSearchResults(newSearchResults);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!queryInput || queryInput.length < 3) {
      setSearchResults([]);
      setSearchResultsQuery("");
      return;
    }

    const timer = setTimeout(() => {
      runSearch();
    }, 500);

    return () => clearTimeout(timer);
  }, [queryInput]);

  const onToggleOpen = (el: HTMLElement) => {
    if (!anchorSearch) {
      setAnchorSearch(el);
      setTimeout(() => {
        const input = boxRef.current?.querySelector("input");
        input?.focus();
      }, 100);
    } else {
      setAnchorSearch(null);
    }
  };

  if (!access.viewCvBase) {
    return <></>;
  }

  return (
    <Box>
      <IconButton
        onClick={(e) => onToggleOpen(e.currentTarget)}
        sx={{
          margin: "0 3px",
          boxShadow: anchorSearch ? `0 0 0 2px ${COLORS["primary"]}` : "none",
        }}
      >
        <SearchNormal1 size={"22px"} style={{ color: COLORS["text-label"] }} />
      </IconButton>

      <Popover
        anchorEl={anchorSearch}
        open={!!anchorSearch}
        onClose={() => setAnchorSearch(null)}
        sx={{
          marginTop: "48px",
        }}
      >
        <Stack
          ref={boxRef}
          sx={{
            padding: "0px",
            width: "500px",
            gap: "5px",
          }}
        >
          <Stack
            gap={"5px"}
            sx={{
              position: "sticky",
              top: "0px",
              padding: "15px 15px 10px 15px",
              backgroundColor: COLORS["text-contrast"],
              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
              zIndex: 1,
            }}
          >
            <TextField
              value={queryInput}
              onChange={(e) => setQueryInput(e.target.value)}
              placeholder="Search for a candidate"
              slotProps={{
                input: {
                  endAdornment: isLoading ? <CircularProgress size={"1rem"} /> : null,
                },
              }}
            />
            <Typography variant="body2">
              <b>
                {t`Search results`}{" "}
                {searchResultsQuery && !isLoading ? `for "${searchResultsQuery}"` : ``}
              </b>
            </Typography>
          </Stack>

          <Stack gap={"4px"}>
            {searchResults.map((result, index) => {
              const isLast = index === searchResults.length - 1;
              return (
                <CandidateRow
                  key={result.id}
                  isLast={isLast}
                  candidate={result.preview}
                  id={result.id}
                />
              );
            })}

            {!isLoading && searchResults.length === 0 && (
              <Typography
                variant="caption"
                sx={{
                  color: COLORS["text-secondary"],
                  padding: "5px 15px",
                }}
              >
                {t`No results`}
              </Typography>
            )}

            {isLoading && (
              <Typography
                variant="caption"
                sx={{
                  color: COLORS["text-secondary"],
                  padding: "5px 15px",
                }}
              >
                {t`Searching...`}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
};
