import { t } from "@lingui/core/macro";
import { useEffect, useRef, useState } from "react";
import { convertBytes } from "src/core/Utils/BytesConverter";

import { Button, Stack } from "@mui/material";

import {
  DragAndDropContainer,
  FileButton,
  Icon,
  UploadTextContainer,
  UploadTextInfo,
  UploadTextUnderline,
} from "./styles";

export interface FileUploadButtonProps {
  maxFileSize: number;
  availableExtensions: string[];
  onUpload?: (file: File) => Promise<void> | void;
  onUploadMultiple?: (files: File[]) => Promise<void>;
  multiple?: boolean;
  title?: string;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  buttonStyle?: "text" | "outlined" | "contained";
  disableDragAndDrop?: boolean;
  buttonRender?: (onClick: () => void) => React.ReactNode;
}

export const FileUploadButton = ({
  maxFileSize,
  availableExtensions,
  onUpload,
  multiple,
  onUploadMultiple,
  title,
  disabled,
  size,
  disableDragAndDrop,
  buttonRender,
  buttonStyle,
}: FileUploadButtonProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const availableHtmlInputFormats = availableExtensions
    .map((extension) => "." + extension)
    .join(", ")
    .toLowerCase();
  const extensionsForUI = availableExtensions.length
    ? availableExtensions.join(", ").toUpperCase()
    : t`Any file`;
  const uploadDescription = t`${extensionsForUI} up to ${convertBytes(maxFileSize).toUpperCase()}`;
  const isFileExists = (file: File | null | undefined): file is File => {
    return !!file;
  };
  const uploadFileHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (disableDragAndDrop) {
      return;
    }

    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (event: DragEvent) => {
      if (event.relatedTarget === null) {
        setIsDragging(false);
      }
    };

    const handleDrop = async (event: DragEvent) => {
      event.preventDefault();
      setIsDragging(false);

      const dirtyFiles = event.dataTransfer?.files;
      const cleanedFiles: File[] = [];
      for (let index = 0; index < (dirtyFiles?.length || 0); index++) {
        const selectedFile = dirtyFiles?.item(index);
        if (
          isFileExists(selectedFile) &&
          (!availableHtmlInputFormats ||
            availableHtmlInputFormats.includes(selectedFile?.name.split(".").pop() || ""))
        ) {
          await onUpload?.(selectedFile);
          cleanedFiles.push(selectedFile);
        }
      }

      if (cleanedFiles.length > 0) {
        onUploadMultiple?.(cleanedFiles);
      }
    };

    const handleDragEnd = () => {
      setIsDragging(false);
    };

    const pasteHandler = async (event: ClipboardEvent) => {
      if (!event.clipboardData?.items) {
        return;
      }

      const item = event.clipboardData.items[0];

      if (item.kind !== "file" || !item.type.startsWith("image/")) {
        return;
      }

      const imageBlob = item.getAsFile();
      if (!imageBlob) {
        return;
      }

      const imageUrl = URL.createObjectURL(imageBlob);
      const hiddenContainer = document.createElement("div");
      hiddenContainer.style.position = "absolute";
      hiddenContainer.style.overflow = "hidden";
      hiddenContainer.style.left = "-2000";
      hiddenContainer.style.width = "0";
      hiddenContainer.style.height = "0";
      hiddenContainer.style.visibility = "hidden";
      document.body.appendChild(hiddenContainer);

      const imageElement = document.createElement("img");
      hiddenContainer.appendChild(imageElement);

      imageElement.src = imageUrl;

      const fileExtension = item.type.split("/")[1];
      const fileName = `past_img_${Date.now()}.${fileExtension}`;

      const file = new File([imageBlob], fileName, { type: imageBlob.type });

      await onUpload?.(file);
      await onUploadMultiple?.([file]);
      imageElement.remove();
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("paste", pasteHandler);

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);
    window.addEventListener("dragend", handleDragEnd);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("paste", pasteHandler);

      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
      window.removeEventListener("dragend", handleDragEnd);
    };
  }, [onUploadMultiple, onUpload, disableDragAndDrop]);

  useEffect(() => {
    const changeFileHandler = () => {
      const selectedFiles = fileInputRef.current?.files?.length || 0;
      const cleanedFiles: File[] = [];
      for (let index = 0; index < selectedFiles; index++) {
        const selectedFile = fileInputRef.current?.files?.item(index);
        if (!isFileExists(selectedFile)) {
          return;
        }
        onUpload?.(selectedFile);
        cleanedFiles.push(selectedFile);
      }

      if (cleanedFiles.length > 0) {
        onUploadMultiple?.(cleanedFiles);
      }
    };
    fileInputRef.current?.addEventListener("change", changeFileHandler);
    return () => {
      fileInputRef.current?.removeEventListener("change", changeFileHandler);
    };
  }, [onUpload, onUploadMultiple]);

  const buttonText = title || t`Browse a file`;

  return (
    <>
      {isDragging ? (
        <>
          <DragAndDropContainer>
            <Icon />
            <UploadTextContainer>
              <UploadTextUnderline variant="body2">
                {t`Drag and drop the file(s) here`}
              </UploadTextUnderline>
              <UploadTextInfo variant="caption">{uploadDescription}</UploadTextInfo>
            </UploadTextContainer>
          </DragAndDropContainer>
          <Stack sx={{ height: "4.5rem" }}></Stack>
        </>
      ) : (
        <>
          <input
            type="file"
            multiple={multiple}
            ref={fileInputRef}
            accept={availableHtmlInputFormats}
            hidden
          />
          {buttonRender ? (
            buttonRender(uploadFileHandler)
          ) : size === "medium" ? (
            <Button
              variant={buttonStyle || "outlined"}
              startIcon={<Icon />}
              onClick={uploadFileHandler}
            >
              {buttonText}
            </Button>
          ) : (
            <FileButton size={size} type="button" onClick={uploadFileHandler} disabled={disabled}>
              <Icon />
              <UploadTextContainer>
                <UploadTextUnderline variant="body2">{buttonText}</UploadTextUnderline>
                {size !== "small" && (
                  <UploadTextInfo variant="caption">{uploadDescription}</UploadTextInfo>
                )}
              </UploadTextContainer>
            </FileButton>
          )}
        </>
      )}
    </>
  );
};
