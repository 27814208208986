import { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAccess } from "src/core/Access/useAccess/useAccess";
import db from "src/core/Backend/firestore";

import { Vacancy, VacancyStat } from "@common/vacancy.types";

import { useAuth } from "../../../../core/Auth/useAuth";
import { isNeedReview } from "../../lib/isNeedReview";
import { isVacancyClient } from "../../lib/isVacancyClient";
import { isVacancyRecruiter } from "../../lib/isVacancyRecruiter";

interface VacancyDoc {
  id: string;
  data: Vacancy;
  vacancyStat?: VacancyStat;
}

export const useVacancies = (): [VacancyDoc[], boolean] => {
  const auth = useAuth();
  const [vacancyList, loading] = useCollection(auth.uid ? db.collections.vacancies : null);
  const [vacancyStats, loadingStats] = useCollection(auth.uid ? db.collections.vacancyStat : null);
  const access = useAccess();

  const listOfVacancies = useMemo(
    () =>
      vacancyList?.docs
        .map((vacancy) => {
          const stat = vacancyStats?.docs.find((stat) => stat.data().vacancyId === vacancy.id);
          return { data: vacancy.data(), id: vacancy.id, vacancyStat: stat?.data() };
        })
        .filter(({ data }) => {
          if (access.viewVacancies) {
            return true;
          }

          if (!access.viewOnlyOwnVacancies) {
            return false;
          }

          const isClient = isVacancyClient(data, auth.userInfo.email || "");
          const isRecruiter = isVacancyRecruiter(data, auth.userInfo.email || "");
          const isReview = isNeedReview({ vacancy: data, userId: auth.uid });

          return isClient || isRecruiter || isReview;
        })
        .sort((a, b) => {
          return a.data.createdAt > b.data.createdAt ? -1 : 1;
        }) || [],
    [vacancyList, vacancyStats],
  );

  return [listOfVacancies, loading || loadingStats];
};
