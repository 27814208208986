import { CustomModal } from "src/uiKit/Modal/CustomModal";

import { RequestTimeOffForm } from "./RequestTimeOffForm";

interface RequestTimeOffModalProps {
  onClose: () => void;
}

export function RequestTimeOffModal({ onClose }: RequestTimeOffModalProps): JSX.Element {
  return (
    <CustomModal onClose={onClose} width="min(100vw, 900px)">
      <RequestTimeOffForm onClose={onClose} />
    </CustomModal>
  );
}
