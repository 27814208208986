import { Vacancy } from "@common/vacancy.types";

import { getVacancyClientEmails } from "./getVacancyClientEmails";

export const isVacancyClient = (vacancy: Vacancy, email: string) => {
  const responsibleEmails = getVacancyClientEmails(vacancy);

  return !!responsibleEmails.find(
    (responsibleEmail) => responsibleEmail.toLowerCase().trim() === email.toLowerCase().trim(),
  );
};
