import { UsageAiRecord, UsageModal } from "@common/usage.types";

// https://openai.com/pricing

// Fine-tuning models = 0.009
// Input = 0.003
// Output = 0.006

const prohiringEarnPercents = 1.3;
export const baseMonthlyPrice = 20;

export const modalPricePer1kTokensInput: Record<UsageModal, number> = {
  "gpt-3.5-turbo": 0.0005 * prohiringEarnPercents,
  "gpt-4": 0.03 * prohiringEarnPercents,
  "gpt-4-turbo": 0.01 * prohiringEarnPercents,
  //US$5.00 / 1M tokens
  "gpt-4o": 0.005 * prohiringEarnPercents,

  //US$0.150 / 1M input tokens
  "gpt-4o-mini": 0.00015 * prohiringEarnPercents,

  //$0.300 / 1M input tokens
  //"gpt-cv-parser": 0.0003 * prohiringEarnPercents,

  //https://cvparser.ai/subscribe
  //"cv-parser": 0.009 * prohiringEarnPercents,

  // Unified price for all models
  "cv-parser": 0.03,
};

export const modalPricePer1kTokensOutput: Record<UsageModal, number> = {
  "gpt-3.5-turbo": 0.0015 * prohiringEarnPercents,
  "gpt-4": 0.06 * prohiringEarnPercents,
  "gpt-4-turbo": 0.03 * prohiringEarnPercents,
  //US$15.00 / 1M tokens
  "gpt-4o": 0.015 * prohiringEarnPercents,

  //US$0.600 / 1M output tokens
  "gpt-4o-mini": 0.0006 * prohiringEarnPercents,

  //https://cvparser.ai/subscribe
  //"cv-parser": 0.009 * prohiringEarnPercents,

  // $1.200 / 1M output tokens
  //"gpt-cv-parser": 0.0012 * prohiringEarnPercents,

  // Unified price for all models
  "cv-parser": 0.03,
};

export const calculatePriceForUsage = (usage: UsageAiRecord) => {
  const modalPriceInput = modalPricePer1kTokensInput[usage.model] || 0;
  const modalPriceOutput = modalPricePer1kTokensOutput[usage.model] || 0;

  const priceInput = (usage.inputTokensCount / 1000) * modalPriceInput;
  const priceOutput = (usage.outputTokensCount / 1000) * modalPriceOutput;

  const price = priceInput + priceOutput;
  return price;
};
