import { t } from "@lingui/core/macro";
import { CloudAdd, Refresh } from "iconsax-react";
import { FC, useEffect, useState } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Stack, Tooltip, Typography } from "@mui/material";

interface AutoSaveIndicatorProps {
  isSaving: boolean;
}
export const AutoSaveIndicator: FC<AutoSaveIndicatorProps> = ({ isSaving }) => {
  const label = isSaving ? t`Saving...` : t`Data saved`;
  const [showFullLabel, setShowFullLabel] = useState(false);

  useEffect(() => {
    if (isSaving) {
      setShowFullLabel(true);
    } else {
      const timeout = setTimeout(() => {
        setShowFullLabel(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [isSaving]);

  return (
    <Stack
      direction={"row"}
      gap={"6px"}
      alignItems={"center"}
      sx={{
        paddingTop: "3px",
        height: "20px",
      }}
    >
      <Tooltip title={label} placement="bottom" arrow>
        {isSaving ? (
          <Refresh size={18} color={COLORS["text-label"]} />
        ) : (
          <CloudAdd size={18} color={COLORS["text-label"]} />
        )}
      </Tooltip>
      {showFullLabel && (
        <>
          {isSaving ? (
            <Typography
              sx={{
                color: COLORS["text-secondary"],
              }}
              variant="caption"
            >
              {t`Saving...`}
            </Typography>
          ) : (
            <Typography
              sx={{
                color: COLORS["text-secondary"],
              }}
              variant="caption"
            >
              {t`Data saved`}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};
