import { t } from "@lingui/core/macro";
import { doc, setDoc } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { COLORS } from "src/uiKit/assets/styles/colors";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";

import { defaultSourceCategories } from "../Vacancy/data/sourceCategories";

interface SourceSettingsProps {
  vacancyId: string;
}
export const SourceSettings: FC<SourceSettingsProps> = ({ vacancyId }) => {
  const [vacancy, loading] = useDocumentData(doc(db.collections.vacancies, vacancyId));
  const [sourceTypes, setSourceTypes] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isDirty || loading || !vacancy) return;
    setSourceTypes(vacancy.sourceCategories || defaultSourceCategories);
  }, [vacancy, loading]);

  const isNeedToSaveData = () => {
    return (
      JSON.stringify(sourceTypes) !==
      JSON.stringify(vacancy?.sourceCategories || defaultSourceCategories)
    );
  };

  const save = async () => {
    if (!isNeedToSaveData()) return;
    setIsSaving(true);
    const document = doc(db.collections.vacancies, vacancyId);
    await setDoc(document, { sourceCategories: sourceTypes }, { merge: true });
    setIsDirty(false);
    setTimeout(() => setIsSaving(false), 1000);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isDirty) {
        save();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [isDirty]);

  return (
    <Stack gap={"15px"}>
      <Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h3">{t`Candidate sources`}</Typography>
          <AutoSaveIndicator isSaving={isSaving} />
        </Stack>

        <Typography
          variant="caption"
          sx={{
            color: COLORS["text-secondary"],
            maxWidth: "760px",
          }}
        >
          {t`Define the list of common sources from which candidates are typically recruited. This list will appear on each candidate's profile under the "Vacancy" section, providing insight into where the candidate was sourced.`}
        </Typography>
      </Stack>
      <Stack
        gap="5px"
        sx={{
          alignItems: "flex-start",
        }}
      >
        {sourceTypes.map((sourceType, index) => {
          return (
            <Stack
              key={index}
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <TextField
                value={sourceType}
                key={index}
                onChange={(e) => {
                  const newSourceTypes = [...sourceTypes];
                  newSourceTypes[index] = e.target.value;
                  setSourceTypes(newSourceTypes);
                  setIsDirty(true);
                }}
                placeholder={t`Source type`}
                sx={{
                  width: "350px",
                }}
                onBlur={save}
              />
              <IconButton
                onClick={() => {
                  const newSourceTypes = [...sourceTypes];
                  newSourceTypes.splice(index, 1);
                  setSourceTypes(newSourceTypes);
                  setIsDirty(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
        <Stack
          sx={{
            paddingTop: "10px",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="text"
            onClick={() => {
              setSourceTypes([...sourceTypes, ""]);
              setIsDirty(true);
            }}
          >
            {t`Add new`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
