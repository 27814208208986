import { FC, ReactNode } from "react";

import { Typography } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

type SxFromTypography = React.ComponentProps<typeof Typography>["sx"];

export const CardTitle: FC<{ children: ReactNode; sx?: SxFromTypography }> = ({ children, sx }) => {
  return (
    <Typography
      variant="h4"
      component="h4"
      sx={{
        color: COLORS.text,
        "@media (max-width: 600px)": {
          fontSize: "1.2rem",
        },
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
