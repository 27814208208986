import { t } from "@lingui/core/macro";
import dayjs from "dayjs";

export function getBirthdayInfo(birthDateTimestamp: number): {
  human: string;
  days: number;
  humanDate: string;
} {
  const today = dayjs();
  const birthDate = dayjs(birthDateTimestamp);

  // get human date: 20 January
  const humanDate = birthDate.format("D MMMM");

  // Clone birthDate before changing the year
  let nextBirthday = birthDate.set("year", today.year());

  // If birthday already happened this year, move to next year
  if (nextBirthday.isBefore(today, "day")) {
    nextBirthday = birthDate.set("year", today.year() + 1);
  }

  const diffDays = nextBirthday.startOf("day").diff(today.startOf("day"), "day");

  if (diffDays === 0)
    return {
      human: `Today 🎉`,
      days: diffDays,
      humanDate,
    };
  if (diffDays === 1) return { human: t`Tomorrow`, days: diffDays, humanDate };

  const nextBirthdayIn = nextBirthday.from(today, true);
  return { human: t`In ${nextBirthdayIn}`, days: diffDays, humanDate };
}
