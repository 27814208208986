import { t } from "@lingui/core/macro";
import { useState } from "react";

import { FormHelperText, TextField } from "@mui/material";

import { CounterSymbol, HelperTextWrapper, TextAreaInputWrapper } from "./style";

interface TextAreaInputProps {
  label: string;
  placeholder?: string;
  maxCountSymbol?: number;
  currentText?: string;
  value?: string;
  isRequired?: boolean;
  error?: string;
  onChange?: (textDescription: string) => void;
  rows?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  readonly?: boolean;
  id?: string;
  helperText?: string;
}

export function TextAreaInput({
  label,
  placeholder,
  maxCountSymbol,
  currentText,
  value,
  isRequired,
  onChange,
  error,
  rows,
  onFocus,
  onBlur,
  readonly,
  helperText,
  id,
}: TextAreaInputProps): JSX.Element {
  const [countSymbol, setCountSymbol] = useState(currentText?.length || value?.length || 0);
  const [isValid, setValid] = useState(false);

  const isNeedToShowHelperText = !!error || (!isValid && isRequired) || maxCountSymbol;
  return (
    <TextAreaInputWrapper>
      <TextField
        label={label}
        placeholder={placeholder}
        defaultValue={currentText}
        helperText={helperText}
        value={value}
        multiline
        rows={rows || 5}
        type="text"
        fullWidth
        onChange={(event) => {
          setCountSymbol(event.target.value.length);
          setValid(!event.target.value.length);
          onChange?.(event.target.value);
        }}
        error={!!error || (isValid && isRequired)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={readonly}
        id={id}
        sx={{}}
        slotProps={{
          htmlInput: {
            maxLength: maxCountSymbol,
          },
        }}
      />
      {isNeedToShowHelperText && (
        <>
          <HelperTextWrapper>
            {error ? (
              <FormHelperText error={true}>{error}</FormHelperText>
            ) : (
              <FormHelperText error={isValid && isRequired}>
                {!isValid || !isRequired ? " " : t`Please fill in this field`}
              </FormHelperText>
            )}
            {maxCountSymbol && (
              <CounterSymbol variant="caption">{`${countSymbol} / ${maxCountSymbol}`}</CounterSymbol>
            )}
          </HelperTextWrapper>
        </>
      )}
    </TextAreaInputWrapper>
  );
}
