import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

import { typographyExtraSmall } from "../Typography/style";
import { COLORS } from "../assets/styles/colors";

const style: ComponentsOverrides["MuiFormLabel"] = {
  root: {
    color: COLORS["text-label"],
    ...typographyExtraSmall,
    marginLeft: "4px",
    fontFamily: "var(--font-family)",
    ".MuiFormLabel-asterisk": {
      color: COLORS["error"],
    },
    "&.Mui-disabled": {
      color: COLORS["text-label"],
    },
    "&.Mui-focused": {
      color: COLORS["text-label"],
    },
    "&.Mui-error": {
      color: COLORS["text-label"],
    },
  },
};

export const customFormLabel: GetTypeOfProperty<Components, "MuiFormLabel"> = {
  styleOverrides: style,
};
