import styled from "@emotion/styled";
import { pageSizes } from "src/uiKit/Layouts/styles";
import { menuRadius, menuShadow } from "src/uiKit/Menu/style";
import { tabsTxtTypography } from "src/uiKit/Typography/style";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Button, Link } from "@mui/material";

export const tabColor = COLORS["text-label"];

export const HeaderTab = styled(Link)({
  display: "flex",
  color: tabColor,
  alignItems: "center",
  height: "max-content",
  padding: "18px 16px",
  flexWrap: "wrap",
  whiteSpace: "nowrap",
  ...tabsTxtTypography,

  "&.active": {
    color: COLORS["primary"],
    boxShadow: `inset 0 -2px 0 ${COLORS["primary"]}`,
  },
});

export const HeaderTabWithArrow = styled(Box)({
  display: "flex",
  lineHeight: "1.8rem",
  height: "100%",
  alignItems: "center",
  padding: "20px 1rem",
  gap: "5px",
  position: "relative",
  whiteSpace: "nowrap",

  "&.active": {
    color: COLORS["primary"],
    boxShadow: `inset 0 -3px 0 ${COLORS["primary"]}`,
  },
  "&:hover, &:focus, &:focus-within": {
    ".headerSubmenu": {
      display: "flex",
    },
  },
});

export const HeaderTabWithArrowContainer = styled(Box)({
  position: "absolute",
  zIndex: 9,
  left: 0,
  top: "3.9rem",
  display: "none",
  flexDirection: "column",
  borderRadius: menuRadius,
  boxShadow: menuShadow,
  backgroundColor: COLORS["bg-card"],
  gap: "5px",
});

export const HeaderTabSubMenu = styled(Link)({
  "&:hover, &:focus": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  textDecoration: "none",
  cursor: "pointer",
  color: tabColor,
  padding: "0.5rem 1rem",
  minWidth: "150px",
  gap: "5px",
});

export const HeaderLogo = styled.img({
  flexGrow: 1,
  display: "flex",
  marginRight: "4rem",
  width: "97px",
  height: "30px",
  "@media (max-width: 1100px)": {
    marginRight: 0,
    overflow: "hidden",
  },
});

export const HeaderLogoLink = styled(Link)({
  color: tabColor,
  display: "block",
  cursor: "pointer",
  "@media (max-width: 1100px)": {
    marginRight: 0,
    maxWidth: "40px",
    overflow: "hidden",
  },
});

export const HeaderPart = styled.div<{ left?: boolean }>(({ left }) => ({
  ".hidden": {
    opacity: 0,
    pointerEvents: "none",
  },
  flexGrow: 1,
  display: "flex",
  gap: 0,
  flexWrap: "wrap",
  justifyContent: left ? "start" : "end",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const fullWith = pageSizes.full;

export const AppBarCustom = styled.div(() => ({
  alignItems: "center",
  gap: "1rem",
  position: "fixed",
  display: "flex",
  height: "var(--heeder-height)",

  "@media (max-width: 900px)": {
    position: "relative",
  },
  zIndex: 100,
  top: 0,
  left: 0,
  width: "100vw",
  boxSizing: "border-box",

  paddingLeft: `max(calc(50vw - ${fullWith / 2}px), 20px)`,
  paddingRight: `max(calc(50vw - ${fullWith / 2}px), 20px)`,
}));

export const CustomHeaderButton = styled(Button)({
  whiteSpace: "nowrap",
  minWidth: "150px",
  margin: "4px 0 4px 1rem",
  "@media (max-width: 700px)": {
    margin: "10px 0 10px 0",
  },
});
