import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import { limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { DAY } from "src/core/Utils/time";
import { useCvPreview } from "src/prohiring/Candidates/usePreviewCv/useCvPreview";
import { getFullVacancyTitle } from "src/prohiring/Vacancy/lib/getFullVacancyTitle";
import { isVacancyClient } from "src/prohiring/Vacancy/lib/isVacancyClient";

import { ActionInfo, ActionUrgencyLevel, Vacancy, VacancyStatus } from "@common/vacancy.types";

import { useVacancies } from "../../Vacancy/hooks/useVacancies/useVacancies";
import { HiringFeedContext } from "./useHiringFeedContext";

const getUrgencyByDeadline = (deadlineAt: number): ActionUrgencyLevel => {
  let urgency: ActionUrgencyLevel = "low";

  const isCloseToDeadline = deadlineAt && deadlineAt !== -1 && deadlineAt - Date.now() < DAY * 2;
  const isOverDeadline = deadlineAt && deadlineAt !== -1 && deadlineAt - Date.now() < 0;
  if (isOverDeadline) {
    urgency = "hight";
  } else if (isCloseToDeadline) {
    urgency = "middle";
  }

  return urgency;
};

const getTimeLeftString = (deadlineTimestamp: number): string => {
  return dayjs(deadlineTimestamp).fromNow();
};

const urgencyVacancyStatus: Record<VacancyStatus, number | null> = {
  Draft: 5 * DAY,
  "In Work": null,
  Closed: null,
};

interface UseReminderActionsProps {
  recruitersVacancies: { data: Vacancy; id: string }[];
  myEmail: string;
}
const useReminderActions = ({ recruitersVacancies, myEmail }: UseReminderActionsProps) => {
  const cvPreview = useCvPreview();
  const [remindersActions, setRemindersActions] = useState<ActionInfo[]>([]);
  const calculateReminders = async () => {
    const allVacancyReminders = await Promise.all(
      recruitersVacancies.map(async (vacancy) => {
        const reminders = (vacancy.data.reminders || [])
          .filter((reminder) => !reminder.isRead)
          .filter((reminder) => reminder.emailTo.includes(myEmail));

        const vacancyReminders: ActionInfo[] = await Promise.all(
          reminders.map(async (reminder) => {
            const candidateName = await cvPreview.getCandidateName(reminder.candidateCvId);
            const actionItem: ActionInfo = {
              title: t`Reminder: ` + candidateName,
              subTitle: getFullVacancyTitle(vacancy.data),
              captionInfo: reminder.message,
              timeLeftString: getTimeLeftString(reminder.eventAt),
              timeLeft: reminder.eventAt - Date.now(),
              urgency: getUrgencyByDeadline(reminder.eventAt),
              redirectUrlPath: `/vacancy-cv-board/${vacancy.id}?showReminderCvId=${reminder.candidateCvId}`,
            };
            return actionItem;
          }),
        );

        return vacancyReminders;
      }),
    );

    setRemindersActions(allVacancyReminders.flat());
  };

  useEffect(() => {
    calculateReminders();
  }, [recruitersVacancies, myEmail]);

  return remindersActions;
};

interface UseRecruitersVacancyStatusActionsProps {
  recruitersVacancies: { data: Vacancy; id: string }[];
}
const useRecruitersVacancyStatusActions = ({
  recruitersVacancies,
}: UseRecruitersVacancyStatusActionsProps) => {
  const recruitersVacancyStatusActions = recruitersVacancies
    .filter((vacancy) => {
      const isNeedReview = vacancy.data.isNeedReview;
      if (!isNeedReview) return false;

      const isApproved = !!vacancy.data.approvedBy?.length;
      const isPublished = vacancy.data.publishedLinks.length > 0;

      const isApprovedAndPublished = isApproved && isPublished;
      return !isApprovedAndPublished;
    })
    .map(({ data, id }) => {
      const status = data.status;
      const isApproved = !!data.approvedBy?.length;
      // todo: refactor; not clear filters and maps. looks ugly
      const actionTitle = !isApproved ? t`Send vacancy to review` : t`Publish vacancy`;
      const deadline = data.updatedAt + (urgencyVacancyStatus[status] || 0);

      const actionItem: ActionInfo = {
        title: actionTitle,
        subTitle: getFullVacancyTitle(data),
        captionInfo: "",
        timeLeftString: getTimeLeftString(deadline),
        timeLeft: deadline - Date.now(),
        urgency: getUrgencyByDeadline(deadline),
        redirectUrlPath:
          `/vacancy-editor/${id}` + (!isApproved ? "?vacancyFormStep=3" : "?vacancyFormStep=4"),
      };
      return actionItem;
    });

  return recruitersVacancyStatusActions;
};

interface UseClientVacanciesActionsProps {
  clientsVacancies: { data: Vacancy; id: string }[];
}
const useClientVacanciesActions = ({ clientsVacancies }: UseClientVacanciesActionsProps) => {
  const actionMessageStatus: Record<VacancyStatus, string | null> = useMemo(
    () => ({
      Draft: null,
      "In Work": null,
      Closed: null,
    }),
    [],
  );

  const clientActions = clientsVacancies

    .filter((vacancy) => actionMessageStatus[vacancy.data.status])
    .map(({ data, id }) => {
      const status = data.status;
      const actionTitle = actionMessageStatus[status] || t`Unknown action`;
      const deadline = data.updatedAt + (urgencyVacancyStatus[status] || 0);

      const actionItem: ActionInfo = {
        title: actionTitle,
        subTitle: getFullVacancyTitle(data),
        captionInfo: "",
        timeLeftString: getTimeLeftString(deadline),
        timeLeft: deadline - Date.now(),
        urgency: getUrgencyByDeadline(deadline),
        redirectUrlPath: `/vacancy-editor/${id}`,
      };
      return actionItem;
    });

  return clientActions;
};

interface UseCandidatesActionsProps {
  recruitersVacancies: { data: Vacancy; id: string }[];
  limitCandidates: number;
}
const useCandidatesActions = ({
  recruitersVacancies,
  limitCandidates,
}: UseCandidatesActionsProps) => {
  const cvPreview = useCvPreview();

  const candidatesQuery = useMemo(() => {
    const recruitersVacanciesIds = recruitersVacancies.map((vacancy) => vacancy.id);
    if (recruitersVacanciesIds.length === 0) return null;
    return query(
      db.collections.vacancyCandidates,
      where("vacancyId", "in", recruitersVacanciesIds),
      where("deadlineAt", "!=", -1),
      where("deadlineAt", "<", Date.now() + 2 * DAY),
      orderBy("deadlineAt"),
      limit(limitCandidates),
    );
  }, [recruitersVacancies, limitCandidates]);

  const jobBoardCandidatesQuery = useMemo(() => {
    const recruitersVacanciesIds = recruitersVacancies.map((vacancy) => vacancy.id);
    if (recruitersVacanciesIds.length === 0) return null;
    return query(
      db.collections.vacancyCandidates,
      where("vacancyId", "in", recruitersVacanciesIds),
      where("isNeedAttention", "==", true),
      orderBy("deadlineAt"),
      limit(limitCandidates),
    );
  }, [recruitersVacancies, limitCandidates]);

  const [candidatesRequest] = useCollection(candidatesQuery);
  const [jobBoardCandidatesRequest] = useCollection(jobBoardCandidatesQuery);

  const [candidateActions, setCandidateActions] = useState<ActionInfo[]>([]);

  const candidates = useMemo(
    () => candidatesRequest?.docs?.map((c) => c.data()) || [],
    [candidatesRequest],
  );

  const jobBoardCandidates = useMemo(
    () => jobBoardCandidatesRequest?.docs?.map((c) => c.data()) || [],
    [jobBoardCandidatesRequest],
  );

  const calculateCandidatesActions = async () => {
    const candidateActions = await Promise.all(
      [...candidates, ...jobBoardCandidates].map(async (candidate) => {
        const cvId = candidate.cvId;
        const vacancy = recruitersVacancies.find((vacancy) => vacancy.id === candidate.vacancyId);
        if (!vacancy) return;

        const vacancyTitle = getFullVacancyTitle(vacancy.data);
        const candidateTitle = await cvPreview.getCandidateName(cvId);

        const action: ActionInfo = {
          title: `${candidateTitle}`,
          subTitle: vacancyTitle,
          captionInfo: t`Finish: ${candidate.hiringStep}`,
          timeLeftString: getTimeLeftString(candidate.deadlineAt),
          timeLeft: candidate.deadlineAt - Date.now(),
          urgency: getUrgencyByDeadline(candidate.deadlineAt),
          redirectUrlPath: `/vacancy-cv-board/${vacancy.id}?cvId=${cvId}`,
          isNeedAttention: candidate.isNeedAttention,
        };
        return action;
      }),
    );

    setCandidateActions(candidateActions.filter((action) => action) as ActionInfo[]);
  };

  useEffect(() => {
    calculateCandidatesActions();
  }, [candidates, recruitersVacancies, jobBoardCandidates]);

  return { candidateActions, candidates };
};

export function useProviderHiringFeed(): HiringFeedContext {
  const auth = useAuth();
  const [listOfVacancies, loadingVacancies] = useVacancies();
  const myEmail = auth.userInfo.email || "";
  const pageCount = 10;
  const [limitCandidates, setLimitCandidates] = useState(5);
  const showMore = () => setLimitCandidates((prev) => prev + pageCount);

  const clientsVacancies = useMemo(
    () => listOfVacancies.filter((vacancy) => isVacancyClient(vacancy.data, myEmail)),
    [listOfVacancies],
  );

  const recruitersVacancies = useMemo(
    () =>
      listOfVacancies.filter((vacancy) => {
        return vacancy.data.responsibleRecruitersEmails.includes(myEmail);
      }),
    [listOfVacancies],
  );

  const clientActions = useClientVacanciesActions({ clientsVacancies });
  const recruitersVacancyStatusActions = useRecruitersVacancyStatusActions({ recruitersVacancies });

  const { candidateActions, candidates } = useCandidatesActions({
    recruitersVacancies,
    limitCandidates,
  });
  const remindersActions = useReminderActions({ recruitersVacancies, myEmail });

  return {
    loading: loadingVacancies,
    actionInfo: [
      ...candidateActions,
      ...remindersActions,
      ...clientActions,
      ...recruitersVacancyStatusActions,
    ],

    showMore,
    isFullLoaded: candidates.length < limitCandidates,
  };
}
