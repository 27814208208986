import { t } from "@lingui/core/macro";
import { CustomModal } from "src/uiKit/Modal/CustomModal";

import { useAuth } from "../useAuth";
import { ChangePasswordForm } from "./ChangePasswordForm";

interface ChangePasswordModalProps {
  onClose: () => void;
}

export function ChangePasswordModal({ onClose }: ChangePasswordModalProps): JSX.Element {
  const { user } = useAuth();
  if (!user) {
    return <>{t`Loading...`}</>;
  }

  return (
    <CustomModal onClose={onClose}>
      <ChangePasswordForm onClose={onClose} user={user} />
    </CustomModal>
  );
}
