export const scrollDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: "smooth",
  });
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
  });
};

export const scrollToElement = (elementId: string, offset: number) => {
  const elementToScroll = document.getElementById(elementId);
  if (!elementToScroll) {
    return;
  }
  const scrollPosition = elementToScroll.getBoundingClientRect().top + window.scrollY;
  window.scrollTo({
    top: scrollPosition - offset,
    behavior: "smooth",
  });
};

export const isDomNodeVisible = (nodeId: string, headerHeight?: number) => {
  const node = document.getElementById(nodeId);

  if (!node) return false;

  const rect = node.getBoundingClientRect();
  const fixedHeaderHeight = headerHeight || 60;

  const isTopInScreen = rect.top - fixedHeaderHeight >= 0 && rect.top <= window.innerHeight;
  const isBottomInScreen =
    rect.bottom <= window.innerHeight && rect.bottom - fixedHeaderHeight >= 0;
  if (isTopInScreen || isBottomInScreen) {
    return true;
  }

  const isTopAboveScreen = rect.top - fixedHeaderHeight < 0;
  const isBottomBelowScreen = rect.bottom - fixedHeaderHeight > window.innerHeight;

  if (isTopAboveScreen && isBottomBelowScreen) {
    return true;
  }

  return false;
};
