import dayjs from "dayjs";

import { TimeOff } from "@common/timeOff.types";

export interface TimeOffRange {
  from: number;
  to: number;
}

export interface CalculateTimeOffBalanceInput {
  startTimeStamp: number;
  endTimeStamp: number;
  ranges: TimeOffRange[];
}

interface DayDetails {
  isWeekend: boolean;
  isHoliday: boolean;
  isWorkday: boolean;
}

export interface CalculateTimeOffBalanceOutput {
  totalCount: number;
  workDayCount: number;

  daysDetails: Record<string, DayDetails>;
}

export const calculateTimeOffBalance = (
  input: CalculateTimeOffBalanceInput,
): CalculateTimeOffBalanceOutput => {
  const { startTimeStamp, endTimeStamp, ranges } = input;

  const startDate = dayjs(startTimeStamp).startOf("day");
  const endDate = dayjs(endTimeStamp).startOf("day");

  const daysDetails: Record<string, DayDetails> = {};

  for (const range of ranges) {
    let rangeStart = dayjs(range.from).startOf("day");
    let rangeEnd = dayjs(range.to).startOf("day");

    // Adjust range to fit within start-end window
    if (rangeEnd.isBefore(startDate) || rangeStart.isAfter(endDate)) {
      continue; // Entire range is outside
    }

    if (rangeStart.isBefore(startDate)) rangeStart = startDate;
    if (rangeEnd.isAfter(endDate)) rangeEnd = endDate;

    // Loop through each day in range and add to Set
    let current = rangeStart;
    while (!current.isAfter(rangeEnd)) {
      const dayString = current.format("YYYY-MM-DD");
      const isWeekend = current.day() === 0 || current.day() === 6;
      const isHoliday = false;
      const isWorkday = !isWeekend && !isHoliday;
      daysDetails[dayString] = {
        isWeekend,
        isHoliday,
        isWorkday,
      };
      current = current.add(1, "day");
    }
  }

  const workDayCount = Object.values(daysDetails).filter(
    (dayDetails) => dayDetails.isWorkday,
  ).length;
  const totalCount = Object.keys(daysDetails).length;

  return {
    totalCount,
    workDayCount,
    daysDetails,
  };
};

interface calculateTimeOfBalanceInput {
  allUsersTimeOffs: TimeOff[];
  currentTimestamp: number;
}
interface calculateTimeOfBalanceOutput {
  usedDaysToCurrentDate: number;
  plannedUsedDays: number;
}

export const calculateTimeOfBalance = (
  input: calculateTimeOfBalanceInput,
): calculateTimeOfBalanceOutput => {
  const firstYearDateTimeStamp = dayjs(input.currentTimestamp)
    .startOf("year")
    .startOf("day")
    .valueOf();

  const endOfYearDateTimeStamp = dayjs(input.currentTimestamp)
    .endOf("year")
    .startOf("day")
    .valueOf();

  const allApprovedUsersTimeRanges: TimeOffRange[] = input.allUsersTimeOffs
    .filter((timeOff) => timeOff.status === "approved")
    .map((timeOff) => ({
      from: timeOff.from,
      to: timeOff.to,
    }));

  const usedThisYearUntilNow = calculateTimeOffBalance({
    startTimeStamp: firstYearDateTimeStamp,
    endTimeStamp: input.currentTimestamp,
    ranges: allApprovedUsersTimeRanges.map((timeOff) => ({
      from: timeOff.from,
      to: timeOff.to,
    })),
  });

  const usedThisYearUntilEnd = calculateTimeOffBalance({
    startTimeStamp: firstYearDateTimeStamp,
    endTimeStamp: endOfYearDateTimeStamp,
    ranges: allApprovedUsersTimeRanges.map((timeOff) => ({
      from: timeOff.from,
      to: timeOff.to,
    })),
  });

  return {
    usedDaysToCurrentDate: usedThisYearUntilNow.workDayCount,
    plannedUsedDays: usedThisYearUntilEnd.workDayCount,
  };
};
