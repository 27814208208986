import { useEffect, useState } from "react";

import { companyName, getEmailParamFromUrl, getPasswordParamFromUrl } from "../../Url/url";
import { useAuth } from "../useAuth";
import { CompanySelectForm } from "./CompanySelectForm";
import { SignInForm } from "./SignInForm";

function SignIn(): JSX.Element {
  const emailFromUrl = getEmailParamFromUrl();
  const passwordFromUrl = getPasswordParamFromUrl();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [googleSignInError, setGoogleSignInError] = useState<string>();

  const [email, setEmail] = useState<string>(emailFromUrl || "");
  const [password, setPassword] = useState<string>(passwordFromUrl || "");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const loginWithGoogle = async () => {
    setGoogleSignInError("");
    setLoading(true);
    const authResult = await auth.signInWithGoogle();
    if (authResult?.error) {
      setGoogleSignInError(authResult.error);
      return;
    }
    setLoading(false);
  };

  const validateForm = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email is required to enter");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is incorrect");
      valid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      valid = false;
    }

    return valid;
  };

  const signInAction = async () => {
    setLoading(true);
    setPasswordError("");

    try {
      const result = await auth.signInWithPassword(email.toLowerCase(), password);
      if (result?.error) {
        setPasswordError(result.error);
      } else {
        localStorage.setItem("lastAuthEmail", email);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        if (emailFromUrl) {
          setPasswordError(err.message);
        } else {
          setEmailError(err.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      await signInAction();
    }
  };

  useEffect(() => {
    if (emailFromUrl && passwordFromUrl) {
      signInAction();
    }
  }, [passwordFromUrl, emailFromUrl]);

  if (auth.isAuthorized && !companyName) {
    return <CompanySelectForm />;
  }

  return (
    <SignInForm
      loading={loading || auth.loading}
      handleSubmit={handleSubmit}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      password={password}
      setPassword={setPassword}
      passwordError={passwordError}
      loginWithGoogle={loginWithGoogle}
      googleSignInError={googleSignInError}
    />
  );
}

export default SignIn;
