import { t } from "@lingui/core/macro";
import React, { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Card, CardContent, Link, Stack, Tooltip, Typography } from "@mui/material";

import { TimeOff } from "@common/timeOff.types";

const getTodayStartTimeStamp = () => {
  // Should return the start of the day in UTC
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.getTime();
};

const getTodayEndTimeStamp = () => {
  // Should return the end of the day in UTC
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  return today.getTime();
};
const getTomorrowStartTimeStamp = () => {
  // Should return the start of the day in UTC
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow.getTime();
};
const getTomorrowEndTimeStamp = () => {
  // Should return the end of the day in UTC
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(23, 59, 59, 999);
  return tomorrow.getTime();
};

const isRangeToucheTimestamp = (from: number, to: number, timestamp: number) => {
  const start = from;
  const end = to;
  return timestamp >= start && timestamp <= end;
};

const isToday = (from: number, to: number) => {
  const todayStart = getTodayStartTimeStamp();
  const todayEnd = getTodayEndTimeStamp();
  return isRangeToucheTimestamp(from, to, todayStart) || isRangeToucheTimestamp(from, to, todayEnd);
};

const isTomorrow = (from: number, to: number) => {
  const tomorrowStart = getTomorrowStartTimeStamp();
  const tomorrowEnd = getTomorrowEndTimeStamp();
  return (
    isRangeToucheTimestamp(from, to, tomorrowStart) || isRangeToucheTimestamp(from, to, tomorrowEnd)
  );
};

export const WhoIsOutCard: React.FC = () => {
  const { users } = useUsers();
  const auth = useAuth();

  const [timeOffList] = useCollection(db.collections.timeOff);

  const listOfTimeOff = useMemo(() => {
    return (
      timeOffList?.docs
        .map((timeOff) => timeOff.data())
        ?.filter((timeOff) => {
          const isTodayTimeOff = isToday(timeOff.from, timeOff.to);
          const isTomorrowTimeOff = isTomorrow(timeOff.from, timeOff.to);
          const isTodayOrTomorrow = isTodayTimeOff || isTomorrowTimeOff;
          const isApproved = timeOff.status === "approved";

          return isTodayOrTomorrow && isApproved;
        }) || []
    );
  }, [timeOffList, auth.uid, users]);

  const filterUniq = (timeOff: TimeOff, index: number) => {
    const userId = timeOff.userId;
    const isUserAlreadyAdded =
      listOfTimeOff.findIndex((timeOff) => timeOff.userId === userId) !== index;
    return !isUserAlreadyAdded;
  };

  const todayTimeOff = useMemo(() => {
    return listOfTimeOff.filter((timeOff) => isToday(timeOff.from, timeOff.to)).filter(filterUniq);
  }, [listOfTimeOff]);

  const tomorrowTimeOff = useMemo(() => {
    return listOfTimeOff
      .filter((timeOff) => isTomorrow(timeOff.from, timeOff.to))
      .filter(filterUniq);
  }, [listOfTimeOff]);

  return (
    <Card
      sx={{
        padding: "20px",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Typography variant="h3">{t`Who’s out`}</Typography>
          <Link href="/calendar" variant="body2">
            {t`Full Calendar`} &gt;
          </Link>
        </Box>
        <Stack
          sx={{
            paddingTop: "20px",
            gap: "20px",
          }}
        >
          <Stack>
            <Typography variant="body2" color="text.secondary" marginBottom={1}>
              {t`Today`}
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "5px",
              }}
            >
              {todayTimeOff.length === 0 && (
                <Typography
                  variant="caption"
                  color={COLORS["text-secondary"]}
                  sx={{ opacity: 0.5 }}
                >
                  {t`Nobody requested time off for today`}
                </Typography>
              )}
              {todayTimeOff.map((timeOff) => {
                const user = users[timeOff.userId];
                const name = user?.fullName || user?.email || "";
                const photoURL = user?.photoURL || "";

                return (
                  <Tooltip key={timeOff.id} title={name} placement="top">
                    <div>
                      <ColorTextAvatar
                        sx={{
                          fontSize: "1rem",
                          width: "36px",
                          height: "36px",
                        }}
                        name={name}
                        src={photoURL || ""}
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="body2" color="text.secondary" marginBottom={1}>
              {t`Tomorrow`}
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "5px",
              }}
            >
              {tomorrowTimeOff.length === 0 && (
                <Typography
                  variant="caption"
                  color={COLORS["text-secondary"]}
                  sx={{ opacity: 0.5 }}
                >
                  {t`Nobody requested time off for tomorrow`}
                </Typography>
              )}
              {tomorrowTimeOff.map((timeOff) => {
                const user = users[timeOff.userId];
                const name = user?.fullName || user?.email || "";
                const photoURL = user?.photoURL || "";
                return (
                  <Tooltip key={timeOff.id} title={name} placement="top">
                    <div>
                      <ColorTextAvatar
                        sx={{
                          fontSize: "1rem",
                          width: "36px",
                          height: "36px",
                        }}
                        name={name}
                        src={photoURL || ""}
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
