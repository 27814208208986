import styled from "@emotion/styled";
import { COLORS } from "src/uiKit/assets/styles/colors";

export const FeedContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "20px 20px 20px 20px",
  boxSizing: "border-box",
  gap: "10px",
});

export const FeedComment = styled.div({
  display: "flex",
  flexDirection: "column",

  padding: "10px 10px",

  gap: "10px",
  width: "100%",
  boxSizing: "border-box",
  borderRadius: "8px",
  cursor: "pointer",
  ":hover": {
    backgroundColor: COLORS["bg"],
  },
});
