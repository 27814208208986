import { ReactNode } from "react";

import { Box } from "@mui/material";

import { AuthModal, AuthModalContainer } from "./style";

function AuthPage({ formComponent }: { formComponent: ReactNode }): JSX.Element {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        overflow: "hidden",
      }}
    >
      <AuthModalContainer>
        <AuthModal>{formComponent}</AuthModal>
      </AuthModalContainer>
    </Box>
  );
}

export default AuthPage;
