import { ArrowDown2 } from "iconsax-react";
import { FC, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Button, Typography } from "@mui/material";

import { getTestDataIdAttribute } from "../Tests/selectors";
import { HeaderMenuElement } from "./BaseHeader";
import {
  CustomHeaderButton,
  HeaderPart,
  HeaderTab,
  HeaderTabSubMenu,
  HeaderTabWithArrow,
  HeaderTabWithArrowContainer,
  tabColor,
} from "./style";

export const HeaderElements: FC<{
  menuItems: HeaderMenuElement[];
  left?: boolean;
}> = ({ menuItems, left }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const containerElement = useRef<HTMLDivElement>(null);
  const pathname = location.pathname;

  const items = menuItems.map((page) => {
    const isHidden = false;
    const baseClassNames: string[] = [];
    if (isHidden) {
      baseClassNames.push("hidden");
    }

    const isActive =
      page.linkTo === pathname ||
      page.activeLinksTo?.some((link) => pathname.startsWith(link)) ||
      false;
    if (isActive) {
      baseClassNames.push("active");
    }

    const clickHandler = (menuElement: HeaderMenuElement) => {
      if (menuElement.handler) {
        menuElement.handler();
      } else if (menuElement.linkTo) {
        const link = menuElement.linkTo;
        const isThirdPartyLink = link.includes("http");
        if (isThirdPartyLink) {
          const isSignIn = link.includes("sign-in") || link.includes("sign-up");
          if (isSignIn) {
            window.location.href = link;
          } else {
            window.open(link, "_blank");
          }
        } else {
          navigate(link);
        }
      }
    };

    if (!page.child) {
      if (page.type === "Button") {
        return (
          <CustomHeaderButton
            href={page.linkTo}
            key={page.title}
            {...getTestDataIdAttribute(page.testDataId)}
            className={baseClassNames.join(" ")}
            onClick={(e) => {
              e.preventDefault();
              clickHandler(page);
            }}
            variant="outlined"
          >
            {page.title}
          </CustomHeaderButton>
        );
      }

      if (page.type === "EmptyButton") {
        return (
          <Button
            href={page.linkTo}
            key={page.title}
            {...getTestDataIdAttribute(page.testDataId)}
            className={baseClassNames.join(" ")}
            onClick={(e) => {
              e.preventDefault();
              clickHandler(page);
            }}
            variant="text"
            sx={{
              margin: "0 20px",
            }}
          >
            {page.title}
          </Button>
        );
      }
      return (
        <HeaderTab
          href={page.linkTo}
          onClick={(e) => {
            e.preventDefault();
            clickHandler(page);
          }}
          key={page.title}
          {...getTestDataIdAttribute(page.testDataId)}
          className={`${baseClassNames.join(" ")} headerTab`}
          underline="none"
          variant="caption"
        >
          {page.title}
        </HeaderTab>
      );
    }
    if (page.child) {
      const urlList = [
        page.linkTo,
        ...(page.activeLinksTo || []),
        ...page.child
          .map((childElement) => [childElement.linkTo, ...(childElement.activeLinksTo || [])])
          .flat(),
      ];
      const isActive = urlList.some((link) => link && pathname.startsWith(link));
      return (
        <Box
          key={page.title}
          {...getTestDataIdAttribute(page.testDataId)}
          sx={{ color: COLORS["text-secondary"], height: "100%", display: "block" }}
          className={baseClassNames.join(" ")}
        >
          <HeaderTabWithArrow tabIndex={0} className={`${isActive ? "active" : ""}`}>
            <Typography
              variant="caption"
              sx={{
                color: tabColor,
              }}
            >
              {page.title}
            </Typography>
            <ArrowDown2 size="16px" style={{ marginRight: "-8px" }} />
            <HeaderTabWithArrowContainer className="headerSubmenu">
              {page.child.map((menuItem) => (
                <HeaderTabSubMenu
                  key={menuItem.title}
                  {...getTestDataIdAttribute(menuItem.testDataId)}
                  href={menuItem.linkTo}
                  onClick={(e) => {
                    e.preventDefault();
                    clickHandler(menuItem);
                  }}
                >
                  {menuItem.title}
                </HeaderTabSubMenu>
              ))}
            </HeaderTabWithArrowContainer>
          </HeaderTabWithArrow>
        </Box>
      );
    }
  });

  return (
    <HeaderPart ref={containerElement} left={left}>
      {items}
    </HeaderPart>
  );
};
