import styled from "@emotion/styled";

import { COLORS } from "../assets/styles/colors";

export const LongForm = styled.div({
  minHeight: "100vh",
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gridTemplateColumns: "min(30vw, 400px) 1fr",
  width: "100%",
  gap: "0 2vw",
  position: "relative",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "min(38vw, 400px) 1fr",
  },
  "@media (max-width: 700px)": {
    gridTemplateColumns: "1fr",
  },
});

export const LongFormRight = styled.div({
  minHeight: "100vh",
  display: "grid",
  gridTemplateRows: "1fr auto",
  gridTemplateColumns: "1fr min(30vw, 400px)",
  width: "100%",
  gap: "0 2vw",
  position: "relative",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "1fr min(38vw, 400px)",
  },
  "@media (max-width: 700px)": {
    gridTemplateColumns: "1fr",
  },
});

export const LongFormMenu = styled.div({
  position: "sticky",
  top: "65px",
  backgroundColor: COLORS["text-contrast"],
  height: "calc(100vh - 96px)",
  overflowY: "auto",
  "@media (max-width: 700px)": {
    display: "none",
  },
});

export const ButtonsBarContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
});

export const LongFormContent = styled.div({
  paddingBottom: "50px",
  overflowY: "auto",
});

export type PageSizes = "full" | "medium" | "small" | "extra";

export const PageHeaderContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 0 20px 0",
  flexWrap: "wrap",
  gap: "20px",
});

export interface PageSettings {
  size?: PageSizes;
}
export const pageSizes: Record<PageSizes, number> = {
  full: 1270,
  medium: 1000,
  small: 716,
  extra: 2000,
};

export const PageContainerElement = styled.div<PageSettings>({}, ({ size = "full" }) => {
  const width = pageSizes[size];

  return {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingLeft: `max(calc(50vw - ${Math.round(width / 2)}px), 20px)`,
    paddingRight: `max(calc(50vw - ${Math.round(width / 2)}px), 20px)`,
    "@media (max-width: 600px)": {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  };
});
